.help-icon {
    position: fixed;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.5);
    color: #fff;
    padding: 12px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s ease;
    z-index: 1000;
    flex-direction: column;
}

.help-icon.ltr {
    right: 20px;
}
.help-icon.rtl {
    left: 20px;
}
.help-dropdown.ltr {
    right: 0;
}
.help-dropdown.rtl {
    left: 0;
}


.help-icon:hover {
    background: rgba(20, 20, 20, 0.9);
}

.help-dropdown {
    position: absolute;
    bottom: 50px;
    /* right: 0; */
    background: white;
    color: black;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: none;
}

.help-icon:hover .help-dropdown {
    display: block;
}

.help-item {
    padding: 6px 12px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.help-item:hover {
    background: lightgray;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.help-dropdown.sexy-dropdown {
    position: absolute;
    bottom: 60px;
    /* right: 0; */
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    min-width: 200px;
    animation: fadeIn 0.3s ease-in-out;
}

.help-item.sexy-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.help-item.sexy-item:last-child {
    border-bottom: none;
}

.help-item.sexy-item:hover {
    background: rgba(255, 255, 255, 0.2);
}

.video-title {
    margin-top: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.video-description {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #555;
    text-align: center;
    max-width: 500px;
    white-space: pre-line;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}