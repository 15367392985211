.rightText {
    text-align: right !important;
    font-size: 22px !important;
}

.save-buttons-container-vat-withholding {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 20px 0 20px 0;
    padding: 0 20px;
    width: 100%;
    /* Add some padding for better spacing */
}

.report-Type-radio-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.filter-upperTable-container {
    display: flex;
    justify-content: flex-start;
}

.reportTracking-filters-container {
    display: flex;
    margin-top: 20px;
    gap: 40px;
}

.filterOptionContainer {
    display: flex;
    width: var(--Spacing-xl-section, 48px);
    height: var(--Spacing-xl-section, 55px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Spacing-md, 8px);
    background: var(--foundation-blue-light-hover, #E0E5FF);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.filterOptionContainer:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transform: scale(1.05);
}

.advanceVatTaxContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    
}

.advanceVatTaxTitleOperationContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
}

.advanceVatTaxTitle {
    color: var(--black-90, rgba(0, 0, 0, 0.90));
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.advanceVatTaxClose {
    display: flex;
    width: var(--Spacing-xl-section, 48px);
    height: var(--Spacing-xl-section, 48px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Spacing-space-section, 32px);
    background: var(--bg-input, #F5F5F6);
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.advanceVatTaxClose:hover {
    transform: scale(1.1);
    opacity: 0.9;
    background-color: rgba(0, 0, 0, 0.05);
}

.advanceVatTaxClose svg path {
    transition: fill 0.2s ease;
}

.advanceVatTaxClose:hover svg path {
    fill: #d9534f;
    /* Example color for hover */
}

.advanceVatTaxTitleCloseContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0 50px;
}

.advanceVatTax-download-button {
    color: var(--Foundation-Blue-Darker, #111C59);

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}