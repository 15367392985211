.signatureReference-content {
    max-width: 500px;
    margin: auto;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}




.signatureReference-save-button {
    border-radius: 8px;
    background: #304FFF;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    width: 45%;
    height: 45px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signatureReference-save-button:hover {
    background-color: #0062cc;
    color: white;
}

.signatureReference-cancel-button {
    border-radius: 8px;
    border: 2px solid #F9AA2A;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    width: 45%;
    height: 45px;
    justify-content: center;
    align-items: center;
    color: #F9AA2A;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signatureReference-cancel-button:hover {
    background-color: #d9534f;
    color: white;
}

.signatureReference-save-button,
.signatureReference-cancel-button {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signatureCanvasContainer {
    width: 100%;
    height: 200px;
    border: 1px solid #ccc;
    background-color: #F5F5F6;
}

.clearSignature {
    margin-top: 10px;
    width: 100%;
    align-self: flex-start;
}