

.sort-template-container {
    padding: 20px 0 20px 0px;

}

.sort-back-icon{
    margin-left: 50px;
}
.profitAndLossTable {
    /* direction: rtl; */
    height: 100%;
    width: 100%;
    /* background-color: white; */
    margin-top: 10px;
}





/* .card {
    flex: 1;
    overflow-y: visible;
    overflow-x: scroll;
    padding: 20px;
} */


/* .rtl .p-treetable-thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    right: 0; 
} */



/* .p-treetable .p-treetable-tbody>tr>td {
    text-align: right;
    padding-right: 20px;
    padding-left: 0;

}  */

/* .p-treetable .p-treetable-thead > tr > th {
    text-align: right;
    padding-right: 20px;  
    padding-left: 0;      
} */

/* .p-treetable .p-treetable-toggler {
    margin-left: 0.5em;
    margin-right: 0;
    background-color: red;
} */

/* .p-column-title {
    margin-left: 10px;
} */

.profitAndLoss-filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    /* background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1); */
    width: 100%;
    height: 66px;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 10px;
}

.custom-datepicker-popper {
    z-index: 1000;
    /* Adjust the z-index as needed */
}


.rtl-table .p-treetable-thead>tr>th,
.rtl-table .p-treetable-tbody>tr>td,
.rtl-table .p-treetable-tfoot>tr>td {
    padding: 10px 10px 10px 10px;
    width: 200px;
}


.rtl-table .p-treetable-thead>tr>th:not(:first-child),
.rtl-table .p-treetable-tfoot>tr>td,
.rtl-table .p-treetable-tbody>tr>td:not(:first-child) {
    text-align: center !important;
    /* Align text to the right for RTL support */
    direction: ltr;
    /* Keep the direction as left-to-right for numbers */
    /* padding-right: 15px; */
    /* Adjust padding as needed */
    margin-right: 15px;
}

.rtl-table .p-treetable-tfoot>tr>td:first-child{
    text-align: right !important;
    /* Align text to the right for RTL support */
    direction: rtl;
    
}
.p-highlight {
    background-color: #ecfeff;
}

.footerToggle {
    display: flex;
    align-items: center;

}


/* .rtl-table .p-treetable-tfoot>tr>td {
} */


.p-treetable-scrollable-header-box{
    margin-right: 0 !important;
}

.p-treetable-scrollable-footer > div {
    margin-right: 0 !important;
}

.p-treetable-tbody {
    max-height: 400px !important;
    overflow-y: auto !important;
}