.unauthorized-background {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    background-image: url('../../assests/images/login/cover1.svg') !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}