/* Main container for the modal */
.chat-modal-container {
  position: absolute;
  top: 5%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  /* Limit modal height to 90% of the viewport */
  height: 90%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  animation: fadeInScale 0.4s ease-in-out;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.8);
    /* Start slightly smaller */
  }

  to {
    opacity: 1;
    transform: scale(1);
    /* Full size */
  }
}

/* Layout for main content */
.chat-page-layout {
  flex: 1;
  /* Fill the remaining height of the modal */
  display: flex;
  flex-direction: row;
  overflow: hidden;
}


/* Sidebar */
.chat-page-sidebar {
  max-width: 400px;
  background: #f7f9fc;
  border-right: 2px solid #e0e0e0;
  overflow-y: auto;
  transition: width 0.3s ease;
}

/* Header with a gradient background */
.chat-modal-header {
  background: linear-gradient(135deg, #6c5ce7, #74b9ff);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  cursor: grab;
}

.chat-modal-header:hover {
  background: linear-gradient(135deg, #74b9ff, #6c5ce7);
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.2s;
}

.close-btn:hover {
  transform: rotate(90deg);
  color: #d63031;
}



.chat-page-sidebar-upper {
  padding: 20px;
}

.sidebar-header {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.sidebar-viewmode {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sidebar-viewmode button {
  flex: 1;
  background: #ffffff;
  border: 2px solid #dfe6e9;
  border-radius: 20px;
  padding: 10px 15px;
  color: #2d3436;
  font-size: 0.9rem;
  margin: 0 5px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.sidebar-viewmode button.active {
  background: #6c5ce7;
  color: #ffffff;
  border: 2px solid #6c5ce7;
}

.sidebar-viewmode button:hover {
  background: #dfe6e9;
  color: #2d3436;
}

/* Chat main content */
.chat-page-main {
  flex: 1;
  /* Main content takes the remaining space */
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: relative;
}

.chat-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f7f9fc;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 10;
}


.back-icon-chat,
.search-icon-chat {
  font-size: 24px;
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
}

.back-icon-chat:hover,
.search-icon-chat:hover {
  /* transform: scale(1.2); */
  /* Subtle hover effect */
  color: #ffcc00;
  /* Eye-catching hover color */
}

@keyframes typing-blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

@keyframes recording-pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

/* .chat-page-header .chat-status {
  font-size: 0.9rem;
  color: #636e72;
  display: flex;
  align-items: center;
} */

.chat-header-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat-header-details2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.chat-room-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.chat-status {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.chat-typing-indicator,
.chat-recording-indicator,
.status-badge {
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 16px;
  display: inline-block;
}


.chat-typing-indicator {
  background-color: #ffefa1;
  color: #705d00;
  animation: typing-blink 1.5s linear infinite;
}

.chat-recording-indicator {
  background-color: #ff8a80;
  color: #fff;
  animation: recording-pulse 1.5s ease-in-out infinite;
}

.status-badge {
  background-color: #e0e0e0;
  color: #666;
}

.status-badge.online {
  background-color: #d4fcd4;
  color: #2e7d32;
}

.status-badge.offline {
  background-color: #fce4e4;
  color: #c62828;
}

/* .status-badge.offline {
  color: #d63031;
} */

/* Messages */
.chat-page-messages {
  /* flex: 1; */
  /* padding: 20px; */
  /* overflow-y: auto; */
  background: #f7f9fc;
  /* display: flex;
  flex-direction: column;
  gap: 15px; */
}

.message-list .rce-message-box {
  margin-bottom: 10px;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Input area */
.chat-page-input {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top: 2px solid #e0e0e0;
  background: #f7f9fc;
}

.chat-page-input input {
  flex: 1;
  border: 2px solid #dfe6e9;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #2d3436;
  transition: border 0.3s, background 0.3s;
}

.chat-page-input input:focus {
  border: 2px solid #6c5ce7;
  background: #ffffff;
  outline: none;
}

.send-button-modern {
  margin-left: 10px;
  background: #6c5ce7;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.send-button-modern:hover {
  background: #74b9ff;
  transform: scale(1.1);
}


.chat-list-container {
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  /* Horizontal padding for the upper section */
  box-sizing: border-box !important;
}

.loading-spinner,
.empty-message {
  text-align: center;
  font-size: 16px;
  color: #666;
  padding: 20px;
}

.chat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
}

.chat-item:hover {
  background: #f5faff;
  /* Light blue background on hover */
  transform: scale(1.02);
  /* Slightly enlarge the item */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Add a soft shadow */
  border-radius: 8px;
  /* Rounded corners on hover */
}

.avatar-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.avatar-info-container-item {
  display: flex;
  align-self: flex-start;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-item:hover .avatar {
  transform: rotate(10deg);
  /* Slight rotation for the avatar */
}

.info-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box !important;
  width: 100%;
  overflow: hidden;
  /* Prevents content overflow */
  justify-content: space-between;
  /* Keeps items at opposite ends */

}

.user-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  transition: color 0.3s ease;
}

.case-type {
  font-size: 12px;
  color: #888;
  margin-top: 2px;
  transition: color 0.3s ease;
}

.last-message {
  font-size: 14px;
  color: #555;
  margin-top: 2px;
  transition: color 0.3s ease;
}

.chat-item:hover .user-name {
  color: #1d4ed8;
  /* Change user name color on hover */
}

.chat-item:hover .case-type,
.chat-item:hover .last-message {
  color: #2563eb;
  /* Change additional text color on hover */
}

.date-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box !important;
}

.date {
  font-size: 12px;
  color: #999;
}

.unread-bubble {
  background: #f9a42a;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  margin-top: 5px;
  text-align: center;
  transition: background 0.3s, transform 0.3s;
}

.unread-bubble2 {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #f9a42a;
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

/* .chat-list {
  height: 80vh;
  width: 100%;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
} */
.chat-list .contact-item-row:hover {
  background-color: #f5faff;
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 20px;
}

.chat-item:hover .unread-bubble {
  background: #e67e22;
  /* Darker orange on hover */
  transform: scale(1.1);
  /* Slightly enlarge the bubble */
}

.contact-item-row:hover {
  background-color: #f5faff;
  /* Light blue background */
  transform: scale(1.02);
  /* Slight enlargement */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Add shadow */
  border-radius: 8px;
  /* Rounded corners */
}

.contact-item-row {
  box-sizing: border-box !important;
  transition: all 0.3s ease;
  margin: 10px 0;
}

.loading-spinner,
.empty-message {
  text-align: center;
  font-size: 16px;
  color: #666;
  padding: 20px;
}


.chat-item-container {
  box-sizing: border-box !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
}


.xtram-chat-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--Dark-56, rgba(0, 0, 0, 0.56));
}

.action-bar-container {
  flex: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.action-bar-container2 {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: white;
  width: 100%;
  position: relative;
}

.message-input-section {
  flex-grow: 1;
  margin-right: 10px;
}

/*  */



.action-button,
.cancel-recording-button {
  background: none;
  /* Remove default button background */
  border: none;
  /* Remove default button border */
  padding: 0;
  /* Remove default padding */
  cursor: pointer;
  /* Show pointer on hover */
  outline: none;
  /* Remove focus outline */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  /* Adjusted button size */
  height: 45px;
  /* Adjusted button size */
  border-radius: 50%;
  /* Make the button a perfect circle */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}



.action-button svg {
  display: block;
  /* Ensure SVG scales properly */
  transition: transform 0.3s ease-in-out;
  /* Smooth transition for the icon */
}


.button-circle {
  pointer-events: none;
  /* Prevent hover effects on the circle itself */
}

.icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover,
.cancel-recording-button:hover {
  transform: scale(1.1);
  /* Slightly enlarge button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Add shadow on hover */
}


.action-button:hover svg {
  transform: scale(1.2);
  /* Slightly enlarge the icon on hover */
}

.text-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  outline: none;
}

.attachment-icons-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.attach-file-button,
.record-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.record-button {
  color: #304fff;
  font-size: 20px;
  font-weight: bold;
}

.record-button.recording {
  color: red;
  font-size: 20px;
  font-weight: bold;
}

.recording-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recording-text {
  margin: auto;
}


/* The parent container that holds MessageList. 
   Make sure it can scroll properly. */


/* The .rce-mlist class is the container for messages. */
.rce-mlist {
  background-color: transparent;
  padding: 0 !important;
  /* remove default padding if you want */
  margin: 0 !important;
}

/* Every message bubble has .rce-mbox, 
     with position: left or right. */

/* My (sent) messages */
.rce-mbox.rce-mbox-right {
  background-color: #007aff !important;
  /* iOS-style blue */
  color: #ffffff !important;
  border-radius: 8px !important;
  margin-left: auto !important;
  /* push to the right */
}

/* Received messages */
.rce-mbox.rce-mbox-left {
  background-color: #F2F2F7 !important;
  color: #2C2C2E !important;
  border-radius: 8px !important;
  margin-right: auto !important;
  /* push to the left */
}

/* The sender’s name (.rce-mbox-title) */
.rce-mbox-title {
  color: #000 !important;
  font-weight: bold !important;
  margin-bottom: 4px;
}

/* The message text (.rce-mbox-text) inherits from the bubble color, 
     but we can override if needed: */
.rce-mbox-text {
  color: inherit !important;
  font-size: 15px !important;
  line-height: 1.4 !important;
}

/* The time stamp displayed in the bubble’s corner (.rce-mbox-time) */
.rce-mbox-time {
  font-size: 12px !important;
  color: #888 !important;
  margin-top: 4px;
}

/* Force a max size for images 
     (react-chat-elements uses .rce-mbox-photo for images) */
.rce-mbox-photo img {
  max-width: 200px !important;
  max-height: 200px !important;
  border-radius: 6px;
  cursor: pointer;
  display: block;
}

/* For audio: adjust the audio player if you want 
     (by default it uses an HTML <audio> tag). */
.rce-mbox-audio audio {
  width: 240px !important;
  /* for example */
  margin-top: 8px;
}

.typingAnimationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Align to the left */
  background-color: white;
  /* Optional: Remove background */
  position: absolute;
  top: -80px;
  left: 20px;
}


.labels-list-container {
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  /* Horizontal padding for the upper section */
  box-sizing: border-box !important;
}

.label-item-row {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.label-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  background-color: #fff;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.label-item-container:hover {
  background-color: #f9f9f9;
}

.label-info-container {
  display: flex;
  flex-direction: column;
}

.label-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.user-count {
  font-size: 14px;
  color: #777;
  margin-top: 4px;
}

.label-actions-container {
  display: flex;
  align-items: center;
}

.delete-icon {
  color: #ff4d4f;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.delete-icon:hover {
  color: #ff7875;
}

.delete-button {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff7875;
}


/* CreateLabelModal Add and Remove Container */
.create-label-modal-add-remove-container {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
}

.create-label-modal-add-button {
  cursor: pointer;
  background-color: #304FFF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.create-label-modal-add-button:hover {
  background-color: #506CFF;
}

.create-label-modal-add-icon {
  width: 20px;
  height: 20px;
  fill: #fff;
}

/* CreateLabelModal Modal Overlay */
.create-label-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* CreateLabelModal Modal Container */
.create-label-modal-container {
  position: fixed;
  /* Fix it in the middle of the screen */
  top: 40%;
  left: 25%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.create-label-modal-container h2 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

/* CreateLabelModal Modal Input */
.create-label-modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box !important;
}

/* CreateLabelModal Modal Buttons */
.create-label-modal-buttons {
  display: flex;
  justify-content: space-between;
}

.create-label-modal-create-button,
.create-label-modal-cancel-button {
  background-color: #304FFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.create-label-modal-cancel-button {
  background-color: #999;
}

.create-label-modal-create-button:hover {
  background-color: #506CFF;
}

.create-label-modal-cancel-button:hover {
  background-color: #bbb;
}

.create-label-modal-add-remove-container {
  display: flex;
  justify-content: flex-start;
  margin: 16px;
}

.create-label-modal-add-button {
  cursor: pointer;
  background-color: #304FFF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.create-label-modal-add-button:hover {
  background-color: #506CFF;
}

.create-label-modal-add-icon {
  font-size: 24px;
  /* Adjust size as needed */
  color: #fff;
}

.search-input-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px 10px;
  width: 100%;
}

.search-chat-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
  color: #333;
}

.search-chat-input::placeholder {
  color: #aaa;
}


.close-icon {
  font-size: 20px;
  color: #aaa;
  cursor: pointer;
  margin: 0 5px;
}

.close-icon:hover {
  color: #007bff;
}

.search-results {
  padding: 10px;
  background: #f9f9f9;
  overflow: auto;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

.search-result-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.search-result-text {
  flex: 1;
  margin-right: 10px;
}

.search-result-excerpt {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin: 0;
}

.search-result-meta {
  font-size: 12px;
  color: #888;
  margin: 0;
}

.search-result-avatar {
  margin-left: 10px;
}

.you-pill {
  background-color: #304fff;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 10px;
}

.getall-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  margin-left: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
}

.custom-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-checkbox:checked {
  background-color: #304FFF;
  /* Primary color */
  border-color: #304FFF;
}

.custom-checkbox:checked::before {
  content: '✔';
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.getall-checkbox-text {
  font-size: 14px;
  font-weight: 500;
  color: #304FFF;
  /* Match primary theme */
  transition: color 0.2s ease;
}

.getall-checkbox-label:hover .getall-checkbox-text {
  color: #1a34cc;
  /* Slightly darker hover color */
}