.sidebar {
    position: fixed;
    top: 0;
    width: 30px;
    height: 100vh;
    background-color: #304FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px;
    transition: width 0.4s;
    z-index: 1000;
}

.sidebar.open {
    width: 250px;
    /* Expanded width */
}

/* Position the sidebar on the left for LTR and right for RTL */
.sidebar.ltr {
    left: 0;
    right: auto;
}

.sidebar.rtl {
    right: 0;
    left: auto;
}

.sidebar-header {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.sidebar-logo {
    width: 56px;
    height: 79px;
}

.sidebar-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: var(--Spacing-md, 8px);
    margin-bottom: 20px;
    padding: 5px;
}


/* 
.sidebar-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: var(--Spacing-md, 8px);
    margin-bottom: 24px;
}
 */

/* Reverse the order of icon and text for RTL */
.sidebar-item.rtl {
    flex-direction: row;
}

.sidebar-item.ltr {
    flex-direction: row;
}

.sidebar-item-main {
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Light, #EAEDFF);
}

.icon-closed {
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Light, #EAEDFF);
    padding: 8px;
    /* Add padding to create the background effect around the image */
}

.sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-item span,
.submenu-item-content span,
.sidebar-item-content span {
    color: var(--white, #FFF);
    font-family: 'Alef', sans-serif;
    /* Or 'Rubik', or 'Assistant' */
    font-size: 16px;
    font-weight: bold;
}


.sidebar-item.ltr span {
    text-align: left;
}

.sidebar-item.rtl span {
    text-align: right;
}

.sidebar-item-main span {
    color: var(--Foundation-Blue-Normal, #304FFF);
    font-family: 'Alef', sans-serif;
    /* Or 'Rubik', or 'Assistant' */
    font-size: 16px;
    font-weight: bold;
}

.logout-item {
    margin-top: auto;
    /* Pushes the logout item to the bottom */
    padding: 10px;
    cursor: pointer;
    margin-bottom: 110px !important;
}

/* Adjust alignment for RTL and LTR */
.sidebar-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.sidebar-items.rtl,
.logout-item.rtl {
    align-items: flex-end;
}

.sidebar-items.ltr,
.logout-item.ltr {
    align-items: flex-start;
}

.submenu {
    width: 100%;
    height: 400px;
    /* Adjust as needed */
    overflow-y: auto;
}

/* Scrollbar Styling */
.submenu::-webkit-scrollbar {
    width: 8px;
}

.submenu::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.submenu::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.submenu-item {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: var(--Spacing-md, 8px);
}

.submenu-item-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.submenu-item img {
    margin-right: 10px;
}

.submenu-item-arrow {
    transition: transform 0.2s;
}

.submenu-item-arrow svg {
    fill: white;
}

.submenu-item-arrow .rotated {
    transform: rotate(180deg);
}

.submenu-subitems-container {
    background-color: white;
    padding: 10px;
    /* margin-left: 20px; */
    border-radius: 5px;
}

.submenu-subitem {
    display: flex;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
    justify-content: space-between;
}

.submenu-subitem:hover {
    background: #F0F0F0;


}

.submenu-subitem img {
    margin-right: 10px;
}

/* .item-notification-count {
    background-color: red;
    color: white;
    border-radius: 12px;
    padding: 2px 6px;
    font-size: 12px;
    margin-left: auto;
} */

.submenu-subsubitems {
    margin-left: 20px;
}

.submenu-subsubitem {
    display: flex;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
}

.submenu-subsubitem img {
    margin-right: 10px;
}



.sidebar-item-content {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.sidebar-item-arrow {
    display: flex;
    transition: transform 0.2s;
}

.sidebar-item-arrow svg.rotated {
    transform: rotate(180deg);
}

.submenu-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.submenu-subsubitem:hover {
    background-color: #F0F0F0;
}

.date-picker-container {
    display: flex;
    gap: 16px;
    margin-top: 16px;
}



.flyout-submenu.rtl {
    left: auto;
    right: 100%;
}

.flyout-submenu-item {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flyout-submenu-item-main {
    overflow-y: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flyout-submenu-item:hover {
    background-color: #f0f0f0;
}

.flyout-submenu-item-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* .item-notification-count {
    background-color: red;
    color: white;
    border-radius: 12px;
    padding: 2px 6px;
    font-size: 12px;
} */

.flyout-submenu {
    position: absolute;
    top: 0;
    left: 100%;
    /* For LTR, adjust for RTL below */
    background-color: #fff;
    min-width: 200px;
    max-height: 300px;
    /* Set maximum height */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 0;
    z-index: 1001;
}

.flyout-submenu-main {
    overflow-y: auto;
    max-height: 220px;
    min-width: 250px;

}

.flyout-subsubmenu {
    position: absolute;
    top: 0;
    left: 100%;
    /* For LTR, adjust for RTL below */
    background-color: #fff;
    min-width: 180px;
    max-height: 200px;
    /* Set maximum height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 0;
    z-index: 1002;
}

.flyout-subsubmenu.rtl {
    left: auto;
    right: 100%;

}

.flyout-subsubmenu-item {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.flyout-subsubmenu-item:hover {
    background-color: #e0e0e0;
}

.flyout-subsubmenu-item-content {
    align-items: center;
    /* gap: 1px; */
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* Adjusting the submenu-section to have relative positioning */
.submenu-section {
    width: 100%;
    position: relative;
}

/* Adjust the submenu-item to handle the flyout properly */
.submenu-item {
    position: relative;
}

/* Adjust for RTL languages */
.sidebar.rtl .flyout-submenu {
    left: auto;
    right: 100%;
}

.flyout-subsubmenu.rtl {
    left: auto;
    right: 100%;
}

.submenu-item-arrow {
    display: inline-flex;
    align-items: center;
}

.submenu-arrow-icon {
    transform: rotate(0deg);
    /* Default for LTR */
    transition: transform 0.2s ease-in-out;
}

/* If the parent element has direction set to rtl */
[dir="rtl"] .submenu-arrow-icon {
    transform: rotate(180deg);
    /* Flip the arrow */
}

.submenu-section.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}