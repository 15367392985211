.template-page-clients {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    background: #FCFCFF;
}

.client-details-container {
    flex-grow: 1;
    /* Grow to use available space */
    margin-right: 320px;
    /* Adjust the right margin to prevent touching the hamburger menu */
    padding: 50px;
    overflow-y: auto;
    /* Allows for scrolling within the container */
    box-sizing: border-box;
    background: #FCFCFF;
    /* Include padding and border in the element's total width and height */

}

.client-back-icon {
    width: 45px;
    height: 45px;
    border-radius: var(--Spacing-space-section, 32px);
    background: var(--bg-input, #F5F5F6);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.clients-row-buttons-icon {
    margin-right: 10px;
}

.client-arrow {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* Space out children */
    padding: 0 10px;
    /* Add some padding if needed */
}

.download-icon {
    align-self: flex-end;
    margin: 0 5px;
    /* Adjust margin for visual balance */
}

.date-range-toggle {
    margin: 0 5px;
    /* Adjust margin for visual balance */
}

.date-range-toggle-active {
    align-self: flex-end;
}

.main-card-title-edit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 100px;
}

.date-picker .date-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    background-color: #fff;
    margin-right: 10px;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    width: 145px;
    height: 39px;
    text-align: center;
}

.date-picker:first-child button {
    cursor: pointer;
}


.custom-input:hover {
    background-color: #E57C22;
    /* Darker shade of blue on hover */
    transform: scale(1.05);
    /* Slightly increase button size on hover */
}

.custom-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 22px;
    line-height: 20px;
    color: #555;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    width: 200px;
    height: 46px;
    text-align: center;
    cursor: pointer;
}


.bidate-custom-input:hover {
    background-color: #E57C22;
    /* Darker shade of blue on hover */
    transform: scale(1.05);
    /* Slightly increase button size on hover */
}

.bidate-custom-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 22px;
    line-height: 20px;
    color: #555;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    height: 40px;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.bidate-custom-input p {
    color: var(--Drak-80, rgba(0, 0, 0, 0.80));
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.client-document-download {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--Spacing-md, 8px);
    background: var(--orange, #F9AA2A);
    padding: var(--Spacing-lg, 12px) var(--Spacing-md, 8px);
    gap: 5px;

    /* Remove borders */
    color: white;

    cursor: pointer;
    /* Pointer/hand icon */
    font-size: 16px;

    margin-right: 10px;
    /* Space from the right */
    transition: background-color 0.3s, transform 0.2s;
}

.client-document-download:hover {
    background-color: #0069D9;
    /* Darker shade of blue on hover */
    transform: scale(1.05);
    /* Slightly increase button size on hover */
}

.client-document-download:focus {
    outline: none;
    /* Remove focus outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
    /* Custom focus style with blue glow */
}

.client-document-download:active {
    background-color: #005CBF;
    /* Even darker background for pressed effect */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    /* Inner shadow for depth */
}

.client-document-download-icon {
    cursor: pointer;
    /* margin-top: 10px; */
}

.client-document-download-modal-overlay {
    display: flex;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.client-document-style-icon-download {
    height: 30px;
    /* or any size that fits your design */
    width: 30px;
    /* Maintain aspect ratio */
    /* Additional styles if needed */
    color: red;
}

.client-document-style-icon-export {
    height: 30px;
    /* or any size that fits your design */
    width: 30px;
    transform: rotate(180deg);
}

.client-document-download-modal {
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    max-width: 80%;
    width: auto;
    border-radius: 10px;
    text-align: center;
}

.client-document-download-modal-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    /* Adjust alignment as needed */
    flex-wrap: wrap;
    /* Allows wrapping if the window is too narrow */
    gap: 20px;
    /* Adds space between groups */
}

.client-document-text {
    color: var(--white, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title-text {
    color: var(--foreground, #000);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
}

.download-icon {
    /* Style as needed */
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
}

.style-icon-download {
    height: 30px;
    /* or any size that fits your design */
    width: 30px;
    /* Maintain aspect ratio */
    /* Additional styles if needed */
}





.card-content {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.client-photo {
    /* Add path to client photo and style as needed */
    width: 100.364px;
    height: 92px;
    margin: 0 auto;
}




.financial-cards {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    border-radius: 10px;
    background: #025366;
    /* height: 257px; */
    align-items: center;
}

.profile-accountant-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
    flex-wrap: wrap;
    border-radius: 10px;
    /* height: 257px; */
    align-items: center;
}

.financial-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    width: 500px;
    height: 150px;
    /* Fixed height for uniformity */
    margin: 10px;
    /* Spacing between cards */
}

.noneFlex {
    flex: none !important;

}



.financial-info .financial-card {
    flex: 1;
    /* Each card takes up an equal amount of space */
}

.card-icon {
    /* Style your icons appropriately */
    margin-bottom: 10px;
    /* Add space between icon and title */
    height: 60px;
    /* Example size, adjust as needed */
    width: 60px;
    /* Example size, adjust as needed */
}

.financial-title {
    width: 220px;
    color: #E57C22;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
}

.financial-title-profit {
    text-align: center;
}

.financial-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 219px;
    height: 66px;
    border-radius: 10px;
    background: #D9D9D9;
    color: var(--foreground, #000);
    text-align: center;
    font-family: Poppins;
    font-size: 34px;
    font-weight: 500;
    margin-left: 30px;
}

.financial-amount-profit {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 220px;
    height: 95px;
    border-radius: 10px;
    background: #D9D9D9;
    color: var(--foreground, #000);
    text-align: center;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    margin-left: 30px;
}

.action-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    /* cursor: pointer; */
}

.action-card {
    position: relative;
    /* background: #FFF; */
    border-radius: 4px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    width: 127px;
    height: 127px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.2s ease;
}

/* .action-card.highlight-border {
    border: 2px solid red;
} */

.notification-count-card {
    position: absolute;
    top: -5px;
    /* Adjust as needed */
    right: -5px;
    /* Adjust as needed */
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0 5px;
    font-size: 0.8rem;
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.card-icon {
    width: 127px;
    height: 127px;
}

.card-label {
    color: var(--foreground, #000);

    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* width: 90px; */
}

.main-card {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    /* Pushes the card down from the top */
    margin-left: auto;
    /* Centers the card horizontally */
    margin-right: auto;
    /* Centers the card horizontally */
    /* height: 221px; */
    border-radius: 10px;
    background: #025366;
}

.main-card-2 {
    width: 90%;
    padding: 20px 20px 70px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    /* Pushes the card down from the top */
    margin-left: auto;
    /* Centers the card horizontally */
    margin-right: auto;
    /* Centers the card horizontally */
    /* height: 221px; */
    border-radius: 10px;
    background: #025366;
}

.main-card-title {
    width: 100%;
    /* text-align: left; */
    margin-bottom: 20px;
    margin-top: 20px;
    /* align-self: center; */
}


.input-field-client {
    /* height: 40px; */
    margin: 10px 0 !important;
    border: 0.5px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    background-color: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    /* Full width */
    text-align: center;
    box-sizing: border-box;
    /* height: 40px; */
    /* Fixed height */
    overflow: auto;
    /* Allows scrolling inside the input */
    white-space: nowrap;
    /* Keeps the text in a single line */
}

.profile-accountant-input {
    /* height: 40px; */
    margin: 10px 0 !important;
    border: 0.5px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    background-color: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    /* Full width */
    text-align: center;
    box-sizing: border-box;
    /* height: 40px; */
    /* Fixed height */
    overflow: auto;
    /* Allows scrolling inside the input */
    white-space: nowrap;
    /* Keeps the text in a single line */
}

.input-field-client-prop {
    /* height: 40px; */
    margin: 10px 0;
    border: 0.5px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: 'black';
    background-color: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 30%;
    /* Full width */
    text-align: center;
    box-sizing: border-box;
    height: 40px;
    /* Fixed height */
    overflow: auto;
    /* Allows scrolling inside the input */
    white-space: nowrap;
    /* Keeps the text in a single line */
}

.info-card-text {
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides text that overflows the container */
    text-overflow: ellipsis;
    /* Adds an ellipsis to indicate there's more text */
    width: 100%;
    /* Adjust width as needed */
    /* padding: 0 10px; Optional padding */
    margin-top: 10px;
    color: var(--foreground, #000);

    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
}

.info-card-text-title {
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides text that overflows the container */
    text-overflow: ellipsis;
    /* Adds an ellipsis to indicate there's more text */
    width: 100%;
    /* Adjust width as needed */
    /* padding: 0 10px; Optional padding */
    /* margin-top: 10px; */
    color: var(--background, #FFF);

    text-align: center;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
}

.info-card-text-title-profile {
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides text that overflows the container */
    text-overflow: ellipsis;
    /* Adds an ellipsis to indicate there's more text */
    width: 100%;
    color: var(--background, #000000);
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
}

.main-card-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-card-edit-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.client-photo-container {
    width: 80px;
    height: 80px;
}

.input-field-dropdown-SortCodes {
    /* padding: 0.5rem; */
    /* margin-right: 1rem; */
    width: 100%;
    height: 40px;
    margin: 10px 0;
    /* padding: 10px; */
    border-radius: 5px;
    font-size: 16px;
    color: 'black';
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
}


.input-field-dropdown-Client {
    /* padding: 0.5rem; */
    /* margin-right: 1rem; */
    width: 100%;
    height: 40px;
    margin: 10px 0;
    /* padding: 10px; */
    border-radius: 5px;
    font-size: 16px;
    color: 'black';
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
}

.input-field-dropdown-Client-props {
    /* padding: 0.5rem; */
    /* margin-right: 1rem; */
    width: 80%;
    height: 40px;
    margin: 10px 0;
    /* padding: 10px; */
    border-radius: 5px;
    font-size: 16px;
    color: 'black';
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
}

.container-input-field-client-prop {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.info-card-title-addButtons {
    display: flex;
    justify-content: flex-end;
    /* Space between title and button */
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.info-cards {
    display: flex;
    flex-grow: 1;
    /* Take remaining space */
    justify-content: space-evenly;
    /* Align cards to the start of the container */
    gap: 20px;
    width: 80%;
    /* Adjust the gap between cards */
    flex-direction: column;
}

.info-cards-props {
    /* Align cards to the start of the container */
    width: 100%;
    /* Adjust the gap between cards */
}

.info-card-add-button {
    /* Keeps the button to the right */
    display: flex;
    justify-content: flex-start;
    margin-right: 10px;
}

.info-card {
    /* Style as needed, ensure they have a defined width */
    /* Do not grow or shrink */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    height: 96px;
    border-radius: 7px;
    background: var(--background, #FFF);
    margin-right: 20px;


}

.info-card-Prop {
    width: 100%;
    /* min-width: 500px !important; */
    /* min-height: 254px !important; */
    justify-content: center;
    flex-direction: column;
    /* padding:   10px 10px 40px 10px; */
    display: flex;
    background: var(--background, #FFF);
    border-radius: 0 0 10px 10px;
    /* margin-right: 20px;
    padding: 20px; */
    padding-bottom: 30px;
    /* margin-bottom: 120px; */
}

.info-cards-Prop {
    justify-content: center;
    align-items: center;
}

.info-card-title {
    color: #030303;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 26px;
    text-align: center;
}

.report-title {
    color: #030303;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 26px;
    text-align: center;
}

.info-card-content {
    color: #030303;
    font-size: 18px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-card-title-prop {
    flex-grow: 1;
    color: var(--foreground, white);
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-left: 30px;
}


.download-modal-overlay {
    display: flex;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    animation: fadeInUp 0.2s ease-out;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.transparent-modal-overlay {
    display: flex;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
}

.download-modal {
    background-color: white;
    margin: auto;
    padding: 1px;
    border: 1px solid #888;
    min-width: 600px;
    border-radius: 10px;
    text-align: center;
}

.download-modal-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 0 auto;
    padding: 20px;
    min-width: 600px;
    text-align: center;
    border-radius: var(--Spacing-xl, 16px);
    background: var(--white, #FFF);
}

.download-modal-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.download-modal-button {
    color: white;
    padding: 10px 20px;
    width: 80%;
    height: 50px;
    border-radius: var(--Spacing-md, 8px);
    background: var(--foundation-blue-light-active, #BFC8FF);
    color: var(--Foundation-Blue-Normal, #304FFF);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 auto;
    /* Centers the element horizontally */
    margin-bottom: 20px;
    /* Adds 20px bottom margin */
}

.create-buttons {
    transition: background-color 0.3s ease;
    /* Adjust the duration and easing function as needed */
}

.create-buttons:hover {
    background-color: #E57C22;
    /* Darker shade for hover effect */
}

.download-modal-button:hover {
    opacity: 0.8;
}

.date-range-toggle {
    background-color: rgb(53, 43, 43);
    /* Blue background */
    border-radius: 2px;
    border: 1px solid #025366;
    height: 46px;
    background: #025366;
    /* White text */
    padding: 10px 24px;
    /* Some padding */
    cursor: pointer;
    /* Pointer/hand icon */
    font-size: 16px;
    /* Increase font size */
    border-radius: 5px;
    /* Rounded corners */
    margin-right: 10px;
    /* Space from the right */
    transition: background-color 0.3s, transform 0.2s;
    color: #E57C22;

    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    width: 170px;
    /* 107.692% */
    /* Smooth transition for hover effect */
}

.date-range-toggle:hover {
    background-color: #0069D9;
    /* Darker shade of blue on hover */
    transform: scale(1.05);
    /* Slightly increase button size on hover */
}

.date-range-toggle:focus {
    outline: none;
    /* Remove focus outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
    /* Custom focus style with blue glow */
}

.date-range-toggle:active {
    background-color: #005CBF;
    /* Even darker background for pressed effect */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    /* Inner shadow for depth */
}


.date-picker-label {
    display: block;
    /* Make the label display as a block */
    margin-bottom: 5px;
    /* Spacing between label and input */
    font-size: 14px;
    /* Font size */
    color: #4A4A4A;
    /* Color */
    font-weight: 500;
    /* Font weight */
}

.client-vat-cards {
    display: flex;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 20px;
    /* margin-top: 50px; */
    /* flex-wrap: wrap; */
    flex-direction: column;
    width: 25%;
}

.vat-advances__container {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 25px;
}

.props-profileDetails__container {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 25px;
    padding-bottom: 20px;
}

.client-vat-info {
    flex: 1;
    /* Takes up one part */
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.client-vat-info .client-vat-card {
    flex: 1;
    /* Each card takes up an equal amount of space */
}

.client-vat-card {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: var(--Spacing-md, 8px);
    background: var(--foundation-yellow-light-hover, #FEF2DF);
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    /* justify-content: space-between; */
    gap: 10px;
    height: 88px;
    /* flex-grow: 1; */
    width: 15%;

}



.client-vat-title {
    color: var(--background, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    /* Space between title and amount */
}

.client-Advances-title {
    color: var(--black-90, rgba(0, 0, 0, 0.90));

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.client-vat-title-new {
    color: var(--Foundation-Blue-Normal, #304FFF);
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.client-profile-title-new {
    color: var(--background, #f5f5f5);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    /* Space between title and amount */
}


.client-transactions-title {
    color: var(--background, #000000);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    /* Space between title and amount */
}

.client-profile-subTitle-new {
    color: var(--background, #000000);
    text-align: right;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    /* Space between title and amount */
}

.details-table-headers {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.details-table-headers-profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.details-table-container {
    /* margin: 20px 0 0 0; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* Rounded corners */
    background-color: #02546600;
    overflow: hidden;
}

.vat-table-container {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* Rounded corners */
    background-color: #02546600;
    overflow: hidden;
}

.details-table-container-profile {
    /* margin: 20px 0 0 0; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* Rounded corners */
    background-color: #025366;
    direction: rtl;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    border-radius: 10px;
    /* Rounded corners */
}

.details-custom-table-profile {
    background-color: #025366;

    width: 100%;
}

.details-custom-table {
    /* opacity: 0.6; */
    background: var(--foundation-blue-light-active, #BFC8FF);

}


.details-table-head {
    background-color: #f5f5f5;
}


.vat-input {
    width: 70%;
    padding: 8px;
    margin: 4px 0;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
}

.vat-input:hover {
    border-color: #000000;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.vat-input:focus {
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

.client-vat-amount {
    color: var(--Drak-80, rgba(0, 0, 0, 0.80));
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.client-vat-amount-Withholding {
    color: #E57C22;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    margin-top: 30px;
}

.client-Advances-amount-Withholding {
    color: var(--black-90, rgba(0, 0, 0, 0.90));

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.client-vat-amount-new {
    color: #ffffff;
    text-align: center;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    margin-top: 30px;
}

.vat-card-icon {
    width: 71px;
    height: 60px;
    flex-shrink: 0;
}

.vat-net-card-icon {
    width: 126.282px;
    ;
    height: 89.392px;
}

.vat-pay-card-icon {
    width: 90.002px;
    height: 151.919px;
}

.vat-purchas-card-icon {
    width: 90px;
    height: 120px;
}

.vat-no-card-icon {
    width: 126.282px;
    height: 100px;
}

.profit-card-icon {
    width: 61px;
    height: 49.248px;
}

.income-card-icon {
    width: 52px;
    height: 74.038px;
    flex-shrink: 0;
}

.incomeTax-card-icon {
    width: 58.263px;
    height: 51px;
    flex-shrink: 0;
}

.expense-card-icon {
    width: 58px;
    height: 58px;
    flex-shrink: 0;
}

.PurchaseExpenses-card-icon {
    width: 90px;
    height: 75px;
    flex-shrink: 0;
}

.client-main-details {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 100px;
}

.details-icon {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.details-icon:hover {
    transform: scale(1.05);
}

.props-details-icon {
    width: 25px;
    height: 25px;
}

.props-details-icon:hover {
    transform: scale(1.05);
}

.client-back-icon:hover {
    transform: scale(1.1);
}


.action-card:hover {
    transform: scale(1.05);
}

.info-card-container {
    display: flex;
    justify-content: space-between;
}

.info-card-details {
    color: var(--foreground, #000);

    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

.TotalUploads {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    height: 72px;
    padding: 0px 8px;
    border: 0;
    border-radius: 8px;
    border: 1px solid #025366;
    background: #025366;
    color: var(--background, #FFF);

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    margin: 0 auto;
}



.TotalUploadsSum {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    border-radius: 10px;
    background: #D9D9D9;
    color: var(--foreground, #E57C22);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    /* margin-left: 30px; */
}

.TotalUploadsTitle {
    color: var(--foreground, #E57C22);
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.inactive-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
}

.title-text {
    text-align: center;
    color: #333;
}



@media (min-width: 768px) {
    .financial-info {
        display: flex;
        align-items: stretch;
        /* flex-wrap: wrap; */
        justify-content: space-around;
        flex-direction: column;
    }
}

@media (min-width: 400px) {
    .new-financial-info {
        display: flex;
        align-items: stretch;
        /* flex-wrap: wrap; */
        justify-content: space-around;
        flex-direction: row;
    }
}

@media (max-width: 768px) {

    .header,
    .card-content,
    .client-vat-cards {
        flex-direction: column;
    }

    .header {
        padding-top: 100px;
    }

    /* .header-right {
        margin-top: 20px;
        justify-content: space-between;
        width: 40%;
    } */
    .header-right {
        align-items: center;
        /* Align children to the bottom */
        width: 100%;
        /* Adjust to full width for better spacing */
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }

    .date-range-toggle,
    .download-icon {
        margin: 0 auto;
        /* Adjust margin for visual balance if needed */
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .date-picker {
        width: 100%;
        /* Make date pickers full width on mobile */
        margin-bottom: 10px;
    }

    .template-page-clients {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        /* Adjust padding as necessary */
        gap: 20px;
        margin-top: 30px;
        /* Space between content and hamburger menu */


    }

    .client-details-container {
        margin-right: 0;
        /* Content can take full width on smaller screens */
        padding: 20px;
    }

    .main-card {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .client-photo-container {
        /* Add path to client photo and style as needed */

        margin-bottom: 50px;
    }

    .card-content {
        flex-direction: column;
        align-items: center;
    }

    .info-cards {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }

    .info-card {
        /* Adjustments for smaller screens */
        width: 100%;
    }

    .action-cards {
        justify-content: center;
        /* Center the cards when they're stacked */
        align-items: center;
    }

    .action-card {
        /* width: 100%; */
        /* Full width cards on mobile */
        height: auto;
        /* Adjust height automatically to content */
    }

    .card-icon {
        /* Adjust icon size for mobile if needed */
        width: 60px;
        /* Keep a fixed size for icons on mobile */
        height: 60px;
        /* Keep a fixed size for icons on mobile */
    }

    .client-back-icon {
        margin-bottom: 20px;
        align-self: start;
    }

    /* .date-picker {
        width: 40%;
    } */

    .info-card-title {
        font-size: 16px;
    }

    .info-card-content {
        font-size: 14px;
    }

    .client-document-download-modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .financial-card {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .financial-amount-profit {
        margin: 0 auto;
    }

    .financial-amount {
        margin: 0 auto;
    }

    .info-card-text {
        font-size: 18px;
    }


}

.main-card-3 {
    border-radius: 10px 10px 0 0;
    background-color: #025366;
    /* margin-top: 50px; */
}


@media (min-width: 1560px) {
    .card-content {
        flex-wrap: wrap;
    }


}

.reference-sequence-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    /* 50% of the viewport width */
    background-color: #ffffff;
    /* Ensure background is white */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Shadow for the modal */
    padding: 16px;
    /* General padding inside the modal */
    border-radius: 20px;
    max-height: 80vh;
    /* Limits height to 80% of viewport height */
    overflow-y: auto;
    /* Adds scrolling if content overflows */
    display: flex;
    /* Use flexbox */
    flex-direction: column;
    /* Arrange items vertically */
    justify-content: flex-start;
    /* Align items to the top */
    align-items: center;
    /* Center items horizontally */
    text-align: center;
    /* Center text */
    gap: 16px;
    /* Add space between the items */
}

.action-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-around;
}

.client-action-card {
    position: relative;
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #304FFF);
    display: flex;
    padding: var(--Spacing-xxl, 24px) var(--Spacing-md, 8px);
    justify-content: space-between;
    align-items: center;
    gap: var(--Spacing-md, 8px);
    min-width: 200px
}

.client-action-card-icon-container {
    border-radius: var(--Spacing-md, 8px);
    background: var(--white, #FFF);
    display: flex;
    width: 40px;
    height: 40px;
    padding: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.client-action-card-header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.client-action-card-title {
    color: var(--white, #FFF);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.client-action-card-icon-title-container {
    display: flex;
    gap: 10px;
}

.client-action-card-shape-container-corner {
    position: absolute;
    top: 0;
}

.client-action-card-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #FFF;
    border: 1px solid #BFBFC1;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.32);
    z-index: 1000;
    max-height: 300px;
    overflow: auto;
}

.client-action-card-menu-item {
    padding: 8px 12px;
    gap: 8px;
    cursor: pointer;
}

.client-action-card-menu-item:hover {
    background: #F0F0F0;
}

.item-icon {
    width: 24px;
    height: 24px;
}

.item-label {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.80);
}


.client-action-card-submenu-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border: 1px solid #BFBFC1;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    /* display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; */
}

.client-action-card-submenu-item:hover {
    background-color: #F0F0F0;
    border-color: #000000;
}

.item-notification-count {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    padding: 2px 6px;
    border-radius: 20px;
    background: var(--Foundation-Blue-Normal, #304FFF);
    color: var(--white, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 8px;
    /* Adjust spacing as needed */
}

.item-notification-count-small {
    display: flex;
    justify-content: center;
    width: 10px;
    padding: 1px 4px;
    border-radius: 20px;
    background: var(--Foundation-Blue-Normal, #304FFF);
    color: var(--white, #FFF);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left: 8px;
    /* Adjust spacing as needed */
}



.DataSummary-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88px;
    padding: var(--Spacing-xl, 16px) var(--Spacing-lg, 12px);
    gap: var(--Spacing-xxl, 24px);
    border-radius: var(--Spacing-md, 8px);
    border: 1px solid var(--Disabled, #BFBFC1);
    width: 15%;
}

.DataSummary-card-icon {
    display: flex;
    width: var(--Spacing-xxl-section, 56px);
    height: var(--Spacing-xxl-section, 56px);
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 48px;
    background: var(--Foundation-Blue-Light, #EAEDFF);
}

.incomeExpense-Hover {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    /* Makes the card appear clickable */
}

.incomeExpense-Hover:hover {
    background-color: #f0f0f5;
    /* Light background color on hover */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    /* Shadow for depth effect */
}

.DataSummary-card-titleData-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.DataSummary-card-value {
    color: var(--Foundation-Blue-Darker, #111C59);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;

    line-height: normal;
}

.DataSummary-card-title {
    color: var(--Foundation-Blue-Darker, #111C59);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.client-vat-card-icon {
    border-radius: 48px;
    background: var(--bg-tabbar, #FCFCFF);
    display: flex;
    width: var(--Spacing-xxl-section, 56px);
    height: var(--Spacing-xxl-section, 56px);
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.client-vat-computed {
    border-radius: var(--Spacing-big-size, 64px);
    background: rgba(192, 57, 43, 0.12);
    display: flex;
    height: 28px;
    padding: 10px var(--Spacing-lg, 12px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: var(--Warning, #C0392B);

    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.client-vat-reported {
    border-radius: var(--Spacing-big-size, 64px);
    background: rgba(44, 216, 82, 0.20);
    display: flex;
    height: 28px;
    padding: 10px var(--Spacing-lg, 12px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: var(--Success, #2CD852);

    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.client-vat-difference {
    border-radius: var(--Spacing-big-size, 64px);
    background: var(--Foundation-Yellow-Light, #FEF7EA);
    display: flex;
    height: 28px;
    padding: 10px var(--Spacing-lg, 12px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A));

    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ClientData-container {
    border-radius: var(--Spacing-md, 8px);
    background: var(--white, white);
    display: flex;
    padding: var(--Spacing-xxl, 24px) var(--Spacing-xl, 16px);
    flex-direction: column;
    align-items: flex-end;
    gap: var(--Spacing-xxl, 24px);
    width: 40%;
    margin: 0 auto;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.15);


}

.ClientData-action-container {
    display: flex;
    align-self: baseline;
}

.ClientData-container-profile {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ClientData-container-profile-title {
    color: #000;
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ClientData-container-profile-title-value {
    color: var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A));
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.client-action-card.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.client-action-card.disabled .client-action-card-title {
    color: gray;
}