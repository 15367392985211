.table-header-dropdown {
    position: sticky;
    top: 0;
    background: rgb(234, 233, 233);
    z-index: 1;
    display: flex;
    border-bottom: 1px solid #ddd;
    font-weight: bold;

}

.table-row-dropdown {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    word-break: break-all;
}

.table-cell-dropdown {
    flex: 1;
    padding: 8px;
    text-align: center;
    word-break: break-word;
    /* Alternatively, try break-word if break-all isn't sufficient */
    overflow-wrap: break-word;
    /* Ensures that words will break if needed */
    white-space: normal;
    /* Ensures text can wrap to a new line */
}

.header-cell-dropdown {
    text-align: center;
    font-weight: bold;
}

.action-buttons-dropdown {
    display: flex;
    justify-content: center;
}

.item-text-dropdown {
    word-wrap: break-word;
    white-space: normal;
}

.p-dropdown-item-label {
    width: 100%;
}

.option-container {
    width: 100%;
    height: 50%;
    overflow: auto;
}

.no-scroll {
    overflow: hidden;
}

.custom-dropdown-panel {
    z-index: 10000 !important;
}

.p-dropdown-empty-message {
    cursor: pointer;
    margin: 0 auto;
    display: flex;
}

.p-dropdown-filter {
    direction: rtl;
    padding-right: 50px;
}

/* Center the selected value inside the dropdown */
.centered-dropdown .p-dropdown-label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

/* Optional: To ensure text inside the dropdown is centered as well */
.centered-dropdown .p-dropdown-item {
    text-align: center;
}

/* Adjust padding and alignment if necessary */
.centered-dropdown .p-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
}