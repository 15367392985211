body,
h1,
h2,
table {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.Document {
  display: flex;
  justify-content: space-between;
}

.search-bar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* This will place the language selector on the left and search bar on the right */
  margin-bottom: 20px;
  padding: 0 10px;
  margin-right: 20px;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  /* Take up all available space */
  padding: 0 20px;
  /* Give some space on the sides */
}

.create-selector {
  display: flex;
  width: 124px;
  height: var(--Spacing-xl-section, 28px);
  padding: var(--Spacing-lg, 12px) var(--Spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  color: var(--white, #FFF);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.create-Invoice-selector {
  display: flex;
  width: 200px;
  height: var(--Spacing-xl-section, 28px);
  padding: var(--Spacing-lg, 12px) var(--Spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  color: var(--white, #FFF);
  text-align: center;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

}

.create-Invoice-selector-2 {
  display: flex;
  width: 150px;
  height: var(--Spacing-xl-section, 23px);
  padding: var(--Spacing-lg, 12px) var(--Spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  color: var(--white, #FFF);
  text-align: center;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

}

.create-Invoice-selector-2-small {
  display: flex;
  width: 100px;
  height: var(--Spacing-xl-section, 90px);
  height: 30px;
  padding: var(--Spacing-lg, 5px) var(--Spacing-md, 2px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  color: var(--white, #FFF);
  text-align: center;
  border: none;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

}

.filter-button-document {
  /* width: auto;
  min-width: 140px; */
  height: 36px;
  padding: 0px 8px;
  border-radius: 2px;
  border: 1px solid #025366;
  background: #025366;
  color: var(--background, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  outline: none;
  cursor: pointer;
  width: 200px;
  height: 50px;
  margin-right: 10px;
}


.document__container {
  display: flex;
  align-items: center;
  /* This will push the children to opposite ends */
  flex-wrap: wrap;
  /* Allows items to wrap to the next line on smaller screens */
  margin-top: 20px;
  padding: 0 15px;
  /* Add padding for better spacing */
}

.income__container h1 {
  margin-left: 10px;
  color: #030303;
  font-size: 48px;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 48px;
}

.input-field {
  padding: 0.5rem;
  margin-right: 1rem;
}

.input-field-dropdown {
  margin-right: 1rem;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-sizing: border-box;
}



.filter-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  /* background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1); */
  width: 100%;
  height: 66px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.dropdown {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}

.dropdown:focus {
  border-color: #007bff;
}



/* --------- table css -----------*/
.table-wrapper {
  overflow: hidden;
  overflow-x: auto;
  clear: both;
  /* width: calc(100% - 30px);
  /* padding-right: 50px; */
}

/* Custom Table Styles */
.custom-table {
  width: 100%;
  border-collapse: separate;
  /* Changed to separate for row spacing */
  border-spacing: 0 10px;
  /* Vertical spacing between rows */
  margin-top: 20px;
  table-layout: auto;
  overflow-x: auto;
}

/* Table Header Styles */
.custom-table thead {
  width: 100%;
}

.custom-table thead th {
  padding: 25px;
  color: #E57C22;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  background: #025366;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  border: 1px solid black;
  min-width: 80px;
}

/* Table Row and Data Cell Styles */
/* .custom-table tbody tr {
  background-color: #ffffff;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
} */

.custom-table td {
  padding: 8px;
  color: black;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  background: #025366;
  text-align: center;

}

/* Image Cell Styles */


.pdf-td {
  position: relative;
  width: 300px;
  height: 200px;
  cursor: pointer;
}

.iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  /* Increase the z-index to cover the iframe */
  background-color: transparent;
  /* Keep it invisible */
}

/* In your CSS file */
.iframe-container {
  display: inline-block;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.iframe-container:hover {
  /* Adjust the scale as needed */
  z-index: 1;
  /* Ensure the iframe stays above other elements */
}

.iframe-container iframe {
  width: 100px;
  height: 50px;
}

.zoom-iframe {
  width: 100px;
  height: 50px;
}

.zoomed-iframe {
  /* Adjust the scale as needed */
  width: 25%;
  height: 70%;
  margin-top: 5%;

  position: fixed;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Ensure the iframe stays above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Ensure the iframe stays above other elements */
}

.my-select__control {
  background-color: white;
  /* border-color: blue; */
  /* other styles */
  width: 160px;
  color: #000;
}

.my-select__option--is-selected {
  /* background-color: red; */
  /* other styles */
  color: #000;
}

.my-select__option--is-focused {
  background-color: lightgray;
  /* other styles */
  color: #000;

}

.table-pdf {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  /* z-index: 1; */
  /* Ensure the overlay is above this */
}

/* Styles for Text in Table Items */
.table-items__container text {
  color: #6b6262;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 20px;
}

/* Modal Background */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500 !important;
  /* Ensure it's above other elements */
}

/* Modal Content/Box */
.newDocument-modal-content {
  max-height: 90vh;
  /* Responsive maximum height */
  border-radius: var(--Spacing-xl, 16px);
  background: var(--white, #FFF);
  padding: var(--Spacing-section, 16px) var(--Spacing-xxl, 16px);
  /* Adjust padding to avoid extra space */
  align-items: center;
  width: 40%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 1000 !important;
}

/* Optional: You can add padding for responsive adjustments */
@media (max-width: 768px) {
  .newDocument-modal-content {
    width: 90%;
    padding: var(--Spacing-section, 8px) var(--Spacing-xxl, 8px);
  }
}

.create-buttons:disabled {
  color: #ccc;
  /* Grey text color for disabled state */
  background-color: #6c757d;
  /* Grey background color for disabled state */
  border: 1px solid #6c757d;
  /* Optional: Grey border color for disabled state */
}

/* Input Styles */
/* Input Styles */
.input-field,
.date-input,

.file-upload-container {
  height: 40px;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* Full width */
  box-sizing: border-box;
}

.document-custom-input {
  width: 200px;
  height: 50px;
  /* margin: 10px 0; */
  padding: 10px;
  font-size: 16px;
  color: 'black';
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #025366;
  background: var(--background, #FFF);
}

.download-document-custom-input {
  height: 40px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* Full width */
  box-sizing: border-box;
}

.document-custom-input-range {
  margin-bottom: 15px;
}

.document-download-icon {
  cursor: pointer;
  /* margin-top: 10px; */
}

.document-download-modal-overlay {
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
}

.document-download-modal {
  background-color: white;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 80%;
  width: auto;
  min-width: 200px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000 !important;
}

.document-download-modal-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* Adjust alignment as needed */
  /* Allows wrapping if the window is too narrow */
  gap: 20px;
  /* Adds space between groups */
  z-index: 1000 !important;
}

.download-report-title {
  color: #030303;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 26px;
  text-align: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.download-document-select {
  width: 200px;
}

.download-document-date-picker {
  width: 200px;
}

.download-create-buttons {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  /* flex-grow: 1; */
  width: 180.589px;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);

  background: #E57C22;
  height: 70.024px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.download-create-buttons:hover {
  transform: scale(1.01);
  /* Darker shade for hover effect */
}

.download-Cancel-create-buttons {
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  /* flex-grow: 1; */
  width: 180.589px;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);

  background: white;
  height: 70.024px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.download-Cancel-create-buttons:hover {
  transform: scale(1.01);
  /* Darker shade for hover effect */
}


.cancel-download-create-buttons {
  background-color: #000;
  color: #000;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  /* flex-grow: 1; */
  width: 180.589px;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);
  height: 70.024px;
  background: #FFF;

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.cancel-download-create-buttons:hover {
  background-color: #444;
  /* Darker shade for hover effect */
}

.download-document-input-field,
.my-select__control,
.date-input {
  width: 100%;
  /* Ensures inputs take full width of their container */
}

.download-document-input-field {
  height: 40px;
  /* margin: 10px 0; */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  /* Full width */
  box-sizing: border-box;
  text-align: center;

}

.document-download-modal-buttons-container {
  display: flex;
  justify-content: space-around;
  z-index: 1000 !important;

}

.document-download-modal-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  border-radius: 5px;

}

.document-download-modal-button:hover {
  opacity: 0.8;
}

.download-my-select__control {
  background-color: white;
  /* border-color: blue; */
  /* other styles */
  width: 200px;
  color: #000;
}

.download-my-select__option--is-selected {
  /* background-color: red; */
  /* other styles */
  color: #000;
}

.download-my-select__option--is-focused {
  background-color: lightgray;
  /* other styles */
  color: #000;

}

.download-button-row {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Button Styles */
.document-custom-input1 {
  height: auto !important;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* Full width */
  box-sizing: border-box;
}

.choose-buttons {
  color: white;
  padding: 10px 20px;
  width: 130px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #025366;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 5px;
  flex-grow: 1;
}

.choose-buttons:hover,
.choose-buttons.active {
  background-color: #E57C22;
  /* Darker shade for hover effect */
}

.choose-buttons+.choose-buttons {
  margin: 0 80px 0 0px;
  /* Adds margin to the left of every button that follows another button */
}

.create-buttons {
  color: white;
  padding: 10px 20px;
  width: 120.589px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #025366;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 5px;
  flex-grow: 1;
}

.create-buttons:hover {
  background-color: #E57C22;
  /* Darker shade for hover effect */
}

/* DatePicker Styles - Optional */
.react-datepicker-wrapper {
  width: 100%;
  /* Ensures the picker takes full width of parent */
}

.date-section {
  margin: 10px 0;
  text-align: center;
}

.date-section2 {
  margin: 10px 0;
  text-align: center;
  /* height: 20px; */
}

.date-input-container {
  display: flex;
  align-items: center;
  height: 20px;
}

.date-input-container:first-child button {
  cursor: pointer;
}

.date-input-container:first-child button:hover {
  background-color: #E57C22;
  color: #025366;
}

.date-input-container2 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 160px;
}

.date-input-container2:first-child button {
  cursor: pointer;
}

.date-input-container2:first-child button:hover {
  background-color: #020202;
  color: white;
}

.calendar-icon {

  /* Adjust the left position as needed */
  width: 20px;
  height: 20px;
  /* Adjust the size as needed */
  height: 20px;
  fill: #333;
  /* Icon color */
  cursor: pointer;
  margin-right: 10px;
}

.date-input {
  padding-left: 40px;
  /* Adjust the left padding to make space for the icon */
  /* Add any other styling you need for the input */
}

.button-row {
  display: flex;
  justify-content: space-between;
}

.input-field {
  width: 100%;
  /* ... (other input styles) */
}

.file-upload-container {
  margin: 10px 0;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  box-sizing: border-box;
}

.upload-label {
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: right;
  width: 100%;
  box-sizing: border-box;
}

/* Hide the actual file input visually, but make it still be focusable by screen readers for accessibility */
.input-file {
  display: none;
}

/* Optional: If you want to show the selected file name */
.upload-label::after {
  content: attr(data-file-name);
  display: block;
  margin-left: 10px;
  color: #888;
  font-size: 0.8em;
}

.error-message-create-document {
  color: red;
  margin-bottom: 1rem;
  /* Add space below the error message */
}

.ok-message-create-document {
  color: green;
  margin-bottom: 1rem;
  /* Add space below the error message */
}

/* Make sure the DatePicker input is styled consistently */
.react-datepicker-wrapper {
  width: 100%;
  /* Ensures the picker takes full width of the container */
}

.react-datepicker__input-container {
  width: 100%;
  /* Ensures the picker input takes full width */
}

.create-modal-overlay {
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
}

.back-icon {
  width: 50px;
  height: 50px;
  /* flex-shrink: 0; */
  /* flex: 1; */
  /* background-color: black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.arrow {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.select-field {
  color: 'black';
}

.providerId-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-buttons {
  background: none;
  /* Removes the background */
  border: none;
  /* Removes the border */
  padding: 0;
  /* Removes padding */
  margin: 0;
  /* Removes margin */
  cursor: pointer;
  /* Changes cursor to pointer to indicate clickability */
  outline: none;
  /* Removes the outline */
  align-items: center;
  /* Centers the icon vertically */
  justify-content: center;
  /* Centers the icon horizontally */
}

.row-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row-buttons-icon {
  width: 30px;
  height: 30px;
}

.document-custom-input:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}

.filter-button-document:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}

.back-icon:hover {
  transform: scale(1.1);
}

.back-download-container {
  display: flex;
}

.document-custom-input {
  cursor: pointer;
}

.document-date-range-toggle {
  border-radius: 2px;
  border: 1px solid #025366;

  background: #025366;
  /* Remove borders */
  /* White text */
  padding: 10px 24px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  color: var(--background, #FFF);

  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  /* Rounded corners */
  margin-right: 10px;
  /* Space from the right */
  transition: background-color 0.3s, transform 0.2s;
  /* Smooth transition for hover effect */
  width: 200px;
  height: 50px;
}

.document-date-range-toggle:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}

.document-date-range-toggle:focus {
  outline: none;
  /* Remove focus outline */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  /* Custom focus style with blue glow */
}

.document-date-range-toggle:active {
  background-color: #005CBF;
  /* Even darker background for pressed effect */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  /* Inner shadow for depth */
}

.document-date-picker-label {
  display: block;
  /* Make the label display as a block */
  font-size: 14px;
  /* Font size */
  color: #4A4A4A;
  /* Color */
  font-weight: 500;
  /* Font weight */

}

.document-style-icon-download {
  height: 30px;
  /* or any size that fits your design */
  width: 30px;
  /* Maintain aspect ratio */
  /* Additional styles if needed */
  /* filter: invert(100%); */
}

.document-download {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  /* Increase font size */
  border-radius: 5px;
  /* Rounded corners */
  margin-left: 30px;
  /* Space from the right */
  transition: background-color 0.3s, transform 0.2s;
  /* Smooth transition for hover effect */
  color: var(--background, #FFF);

  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  border-radius: 2px;
  border: 1px solid #025366;

  background: #025366;
  width: 200px;
  height: 50px;
  border-radius: 10px;
}

.document-download:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}

.doc-details-icon:hover {
  transform: scale(1.05);
}

.document-download:focus {
  outline: none;
  /* Remove focus outline */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  /* Custom focus style with blue glow */
}

.document-download:active {
  background-color: #005CBF;
  /* Even darker background for pressed effect */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  /* Inner shadow for depth */
}

.highlighted-row {
  border: 3px solid red;
  border-collapse: collapse;
  position: relative;
  /* Add this to position the star relative to the highlighted row */
}

.highlighted-row td {
  border-top: 3px solid red;
  border-bottom: 3px solid red;
}

.highlighted-row td:first-child {
  border-left: 3px solid red;
}

.highlighted-row td:last-child {
  border-right: 3px solid red;
}

/* Add this to create a red star */
.highlighted-row::after {
  content: '❗';
  /* Unicode character for a star */
  color: red;
  /* Star color */
  position: absolute;
  /* Absolute position relative to the .highlighted-row */
  top: 0;
  /* Position at the top */
  right: 0;
  /* Position at the right */
  font-size: 44px;
  /* Adjust the size of the star */
  /* transform: translate(50%, -50%); Adjust the star's position to make it half outside */
}

.documentDone-icon {
  width: 30px;
  height: 30px;
}

.document-title {
  color: var(--foreground, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
}

.document-search-bar {
  padding: 8px 8px 8px 40px;
  /* Adjust left padding to accommodate the icon */
  border: none;
  box-shadow: 2px 2px 4px rgba(3, 3, 3, 0.1);
  margin: 0 0 0 450px;
  background-color: #ffffff;
  color: #a9a9a9;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  width: 382.548px;
  height: 35.813px;
  max-width: 500px;
  border-radius: 24px;
  background-image: url(../../assests/images/search.png);
  background-position: 10px center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  text-align: right;
  padding-right: 20px;
  /* Maintain original right padding for text alignment */
}

.document-search-bar-expense {
  padding: 8px 8px 8px 40px;
  /* Adjust left padding to accommodate the icon */
  border: none;
  box-shadow: 2px 2px 4px rgba(3, 3, 3, 0.1);
  margin: 0 0 0 230px;
  background-color: #ffffff;
  color: #a9a9a9;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  width: 382.548px;
  height: 35.813px;
  max-width: 500px;
  border-radius: 24px;
  background-image: url(../../assests/images/search.png);
  background-position: 10px center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  text-align: right;
  padding-right: 20px;
  /* Maintain original right padding for text alignment */
}

.document-date-picker {
  width: 200px
}

.td-container {
  width: 100%;
  min-height: 70px;
  background: var(--background, #FFF);
  display: flex;
  align-items: center;
  justify-content: center;
}

.td-container-2 {
  width: 50px;
  min-height: 70px;
  background: var(--background, #FFF);
  display: flex;
  align-items: center;
  justify-content: center;
}

.totalPrice {
  background: var(--background, #E57C22);
}

.totalPrice-new {
  background: var(--background, #f3ff0823);
}


.doc-details-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.doc-details-ico-small {
  width: 15px;
  height: 15px;
}

.doc-details-icon-big {
  width: 60px;
  height: 60px;
}


.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  /* Dim background */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.edit-modal-content {
  width: 100%;
  height: 90%;
  overflow-y: auto;
  /* Enable scrolling inside the modal */
  border-radius: 24px;
  background: var(--background, #FFF);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.edit-modal-content-details {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  width: 100%;
  flex-grow: 1;
  overflow-x: auto;
}


.edit-pdf-td {
  position: relative;
  width: 100%;
  height: 850px;
  cursor: pointer;
  margin-top: 30px;
}

.edit-table-pdf {
  width: 100%;
  height: 95%;
  border-radius: 8px;
  /* position: relative; */
}

.edit-document-title {
  width: 292px;
  height: 70px;
  border-radius: 5px;
  background: #025366;
  color: var(--background, #FFF);

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .edit-modal-content-left-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
} */
.edit-modal-content-left-details {
  width: 50%;
  /* Make it take half the screen */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  /* Add some padding to the left */
}

.edit-button-save {
  width: 150.589px;
  height: 60.024px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);
  background: #E57C22;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--background, #FFF);
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;

}

.edit-button-cancel {
  width: 150.589px;
  height: 60.024px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--foreground, #000);

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-left: 30px;
  cursor: pointer;
}

.edit-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 20px 0 20px 0;
  padding: 0 20px;
  /* Add some padding for better spacing */
}

.edit-button-cancel:hover {
  background-color: #FFF;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}

.create-selector:hover {
  background-color: #F9AA2A;
  transition: background-color 0.3s ease;
}

.create-Invoice-selector:hover {
  background-color: #F9AA2A;
  transition: background-color 0.3s ease;
}

.create-Invoice-selector-2:hover {
  background-color: #F9AA2A;
  transition: background-color 0.3s ease;
}

.create-Invoice-selector-2-small:hover {
  background-color: #F9AA2A;
  transition: background-color 0.3s ease;
}

.edit-button-cancel:focus {
  outline: none;
  /* Remove focus outline */
  box-shadow: 0 0 0 2px #FFF;
  /* Custom focus style with blue glow */
}

.edit-button-cancel:active {
  background-color: rgba(175, 54, 7, 0.5);
  /* Even darker background for pressed effect */
  box-shadow: 0 1px 2px #FFF;
  /* Inner shadow for depth */
}

.edit-button-save:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.01);
  /* Slightly increase button size on hover */
}

.edit-button-save:focus {
  outline: none;
  /* Remove focus outline */
  box-shadow: 0 0 0 2px #E57C22;
  /* Custom focus style with blue glow */
}

.edit-button-save:active {
  background-color: rgba(175, 54, 7, 0.5);
  /* Even darker background for pressed effect */
  box-shadow: 0 1px 2px #E57C22;
  /* Inner shadow for depth */
}

.edit-document-input-name {
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  /* width: 100px; */
}

.edit-document-input-description {
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .edit-modal-content-right-details {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: flex-end;
} */
.edit-modal-content-right-details {
  /* width: 50%; Make it take half the screen */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
  /* Add some padding to the right */
}

/* .edit-document-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-self: flex-start;
  margin-bottom: 20px;
} */

.edit-document-input-container {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.edit-document-input-container-upper-panel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.add-document-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-self: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.edit-modal-content-right-container {
  /* width: 100%; */
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.edit-modal-content-left-container {
  /* width: 100%; */
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 50px;
}

/* .edit-input-field {
  width: 180px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #C2C2C2;
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: var(--foreground, #000);

  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word; 
  overflow: hidden; 
  white-space: normal; 
  padding: 10px;
} */

.edit-input-field {
  width: 180px;
  height: auto;
  /* Adjust height based on content */
  min-height: 70px;
  /* Ensure a minimum height */
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #C2C2C2;
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
  /* Break long words */
  overflow: hidden;
  /* Hide overflowing text */
  white-space: normal;
  /* Allow text to wrap to the next line */
  padding: 10px;
  /* Add padding for better spacing */
  line-height: 1.5;
  /* Adjust line height */
  display: flex;
  /* Use flexbox for aligning text */
  align-items: center;
  /* Center align text vertically */
}


.edit-input-field-2 {
  width: 60px;
  height: auto;
  /* Adjust height based on content */
  min-height: 70px;
  /* Ensure a minimum height */
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #C2C2C2;
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
  /* Break long words */
  overflow: hidden;
  /* Hide overflowing text */
  white-space: normal;
  /* Allow text to wrap to the next line */
  padding: 10px;
  /* Add padding for better spacing */
  line-height: 1.5;
  /* Adjust line height */
  display: flex;
  /* Use flexbox for aligning text */
  align-items: center;
  /* Center align text vertically */
}

.edit-checkBox-field {
  min-width: 250px;
  height: 76px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #C2C2C2;
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: var(--foreground, #000);

  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.edit-my-select__control {
  /* min-width: 206px; */
  min-width: 180px;
  width: auto;
  height: 76px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #C2C2C2;
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.edit-my-select__option--is-selected {
  /* background-color: red; */
  /* other styles */

  color: #000;
}

.edit-my-select__option--is-focused {
  background-color: lightgray;
  /* other styles */
  color: #000;

}

.edit-custom-input {
  /* width: 206px; */
  width: 180px;
  height: 50px;
  border-radius: 5px;
  border: 2px solid #C2C2C2;
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.edit-description-input-field {
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #C2C2C2;
  background: var(--background, #FFF);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: right;
  color: var(--foreground, #000);

  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}

.edit-date-input-container {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* width: 206px; */
  width: 180px;
  height: 76px;
}

.edit-modal-content-up-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-end;
  /* margin-left: 100px; */
  column-gap: 100px;
}

.edit-modal-content-upper-panel {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-end;
  /* margin-left: 100px; */
  column-gap: 50px;
}

.edit-modal-content-up-container,
.edit-modal-content-right-container,
.edit-modal-content-left-container {
  width: 100%;
}

.edit-modal-content-down-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.MonthReportButton {
  display: flex;
  width: 200px;
  height: var(--Spacing-xl-section, 28px);
  padding: var(--Spacing-lg, 12px) var(--Spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  color: var(--white, #FFF);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect */
.MonthReportButton:hover {
  background-color: #F9AA2A;
}

/* When the month is closed, change the button color */
.MonthReportButton.closed-month {
  background-color: red;
}

/* Hover effect when the month is closed */
.MonthReportButton.closed-month:hover {
  background-color: darkred;
}


.items_Container {
  width: 100%;
  margin-top: 10px;
}

/*  */




.clientItemListBack {
  transform: rotate(180deg);
}

.clientItemCancelButton {
  display: flex;
  /* flex-direction: column; */
  background: none;
  /* Removes the background */
  border: none;
  /* Removes the border */
  padding: 0;
  /* Removes padding */
  margin: 0;
  /* Removes margin */
  cursor: pointer;
  /* Changes cursor to pointer to indicate clickability */
  outline: none;
  /* Removes the outline */
  align-items: center;
  /* Centers the icon vertically */
  /* justify-content: flex-end; */
  /* Centers the icon horizontally */
  font-size: 16px;
}

.clientItemCancelButton:hover {
  transform: scale(1.05);
}

.clientItem-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.item_Container {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-field_Container {
  width: 100%;
  display: flex;
  align-items: center;

}

.input-field_Container_required-asterisk {
  border-style: solid !important;
  border-color: red !important;
}

.input-field-title {
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  width: 150px;
}

.modal-Invoice-content {
  background-color: white;
  border-radius: 20px;
  /* padding: 35px; */
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width: 600px;
  max-height: 900px;
  overflow-y: auto;
  /* Fixed width */
  display: flex;
  flex-direction: column;

  max-width: 96%;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 5%;
  height: 600px;
  overflow-y: auto;
  overflow-x: auto;
  z-index: 1000 !important;
}

.accountName_item_Container {
  display: flex;
  width: 100%;

}

.signatureCanvas {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.headerTH {
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  /* Add a transition to smoothly animate the background color, transform, and color */
}

.headerTH:hover {
  /* background-color: #e8e8e8; */
  /* Darker shade of blue on hover */
  transform: scale(1.005);
  color: black;
  /* Slightly increase button size on hover */
}

.headerTH-Text {
  width: 140px;

}

.textRight {
  text-align: right !important;
}

.next-previous-icon {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 50px;
  margin-right: 50px;
}

.next-arrow {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  transform: rotate(180deg);

}

.next-previous-icon:hover {
  transform: scale(1.1);
}

.next-previous__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s;
}

@media (max-width: 809px) {

  .modal-content,
  .modal-Invoice-content {
    width: 90%;
    padding: 20px;
    margin-top: 40%;
    font-size: 0.9em;
    max-height: 500px;
  }

  .create-selector {
    margin-top: 15px;
  }

  .create-Invoice-selector {
    margin-top: 15px;
    margin-right: 0;
  }

  .create-Invoice-selector-2 {
    margin-top: 15px;
    margin-right: 0;
  }


  .create-Invoice-selector-2-small {
    margin-top: 15px;
    margin-right: 0;
  }

  .white-icon {
    filter: brightness(0) invert(1);
    /* Makes SVG white */
  }

  .search-bar__container {
    margin-top: 15px;
    flex-direction: column;
    /* Stack elements vertically */
    margin-bottom: 20px;

  }

  .search-bar-container {
    width: 100%;
    padding: 0 10px;
  }

  .document-custom-input {
    font-size: 12px !important;
  }



  .document-title {
    flex-grow: 1;
    /* This will allow the title to take up as much space as possible, pushing other elements to the right */
    text-align: center;
  }

  .krtest-titles {
    width: 300px;
    height: 100px;
    background-color: #C2C2C2;
    border-radius: 10px;
  }

  .filter-container {
    padding-bottom: 10px;
    padding-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    width: auto !important;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .document__container {
    width: 100%;
    justify-content: space-between;
  }

  .custom-table {
    margin: 0 auto;
    width: 100%;
  }

  .table-wrapper {
    width: 100%;
  }

  .custom-table-container {
    overflow-x: auto;
    margin: 0 -15px;
  }

  .table-container-custom {
    margin-right: 40px;
    overflow-x: hidden;
  }

  .scroll-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .scroll-content {
    height: 40vw;
    overflow: auto;
    transform: rotate(180deg);
  }

  .scroll-content>* {
    transform: rotate(180deg);
  }

  .document-download-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .edit-modal-content {
    margin: 2vh auto;
    width: 95%;
    height: auto;
  }

  .edit-modal-content-details,
  .edit-modal-content-right-details,
  .edit-modal-content-left-details {
    flex-direction: column;
    /* Stack the details vertically on small screens */
    align-items: center;
  }

  .edit-modal-content-details {
    margin-top: 100px;
  }

  .edit-pdf-td,
  .edit-table-pdf {
    width: 90%;
    /* Adjust the width of the PDF preview to fit the modal */
    height: auto;
    /* Adjust the height automatically */
    margin-top: 20px;
  }

  .edit-document-input-container,
  .edit-document-input-name,
  .edit-input-field,
  .edit-my-select__control,
  .edit-custom-input,
  .edit-date-input-container {
    /* width: 100%; Full width for better usability */
    margin-top: 10px;
    /* Add some space between input fields */
  }

  .edit-buttons-container {
    flex-direction: column;
    /* Stack buttons vertically */
    margin-top: 20px;
  }

  .edit-button-save,
  .edit-button-cancel {
    width: 80%;
    /* Wider buttons for easier interaction */
    margin: 10px auto;
    /* Center buttons and add space between them */
  }

  .edit-modal-content-right-container,
  .edit-modal-content-left-container {
    width: 100%;
    /* Use full width for container */
  }

  .edit-modal-content-up-container {
    flex-direction: column;
    width: auto;
    margin: 0 auto
  }

  .edit-description-input-field {
    width: 100%;
  }

  .second-edit-description-input-field {
    width: 100%;
    height: 100%;
  }

  .edit-modal-content-down-container {
    align-self: center;
    margin-top: 25px;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  .document__container {
    justify-content: flex-end;
  }
}

@media (min-width: 1440px) and (max-width: 2200px) {
  .edit-modal-content {
    width: 95%;
    height: 90%;
  }

  .edit-modal-content-details {
    /* justify-content: space-between; */
    overflow-y: none;
  }
}


@media (max-width: 1024px) {
  .edit-modal-content {
    width: 85%;
    max-width: 600px;
  }
}


@media (max-width: 1750px) {
  .edit-modal-content {
    margin: 10% auto;
  }
}

@media (min-width: 810px) and (max-width: 1440px) {
  .edit-modal-content {
    margin: 5% auto;
    width: 90%;
  }

  .edit-modal-content-details {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .edit-modal-content-left-container,
  .edit-modal-content-right-container {
    height: 100%;
  }
}


.table-container {
  /* margin-right: 40px; */
  overflow-x: hidden;
}

.table-upper-panel {
  height: 80px;
  /* Adjust the height as needed */
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
  border-radius: 20px 20px 0 0;
  padding: 20px 20px 0 20px;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.table-upper-panel-kartest {
  /* Adjust the height as needed */
  height: 80px;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
  border-radius: 20px 20px 0 0;
  padding: 20px 20px 0 20px;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.pdf-view-upper {
  background-color: #f2f2f2;

}

.table-upper-panel-ledger {
  height: 90px;
  /* Adjust the height as needed */
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
  border-radius: 20px 20px 0 0;
  padding: 20px 20px 0 20px;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
  justify-content: space-between;
}


.table-upper-panel-other-docs {
  height: 80px;
  /* Adjust the height as needed */
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
  border-radius: 20px 20px 0 0;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.table-upper-dates-panel-other-docs {
  width: 50%;
  display: flex;
  padding: 20px 20px 0 20px;
  gap: 10px;
  flex-direction: row-reverse;
}


.table-upper-dates-panel {
  width: 50%;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.table-upper-dates-panel-right-side {
  width: 50%;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.custom-scrollbar {
  height: 20px;
  /* Adjust the height as needed */
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
}

.custom-scrollbar::-webkit-scrollbar {
  height: 10px;
  /* Adjust the height of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Adjust the color of the scrollbar thumb */
  border-radius: 10px;
  /* Adjust the border radius */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Adjust the color when hovered */
}

.scroll-content {
  height: 40vw;
  overflow: auto;
}

.sticky-header {
  z-index: 1 !important;
  /* A reasonable value for sticky header */
}

.iframe-modal-style {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(50%, 10%);
  width: 50%;
  height: 90%;
}

.react-datepicker-popper {
  z-index: 3;
}

.custom-input-box-sizing .MuiInputBase-input {
  box-sizing: content-box !important;
  text-align: right;
}

.custom-box-sizing-border-box {
  box-sizing: border-box !important;
}

.custom-input-box-sizing-toggle {
  box-sizing: border-box !important;
}

.custom-input-box-sizing-button {
  box-sizing: border-box !important;
  background-color: red !important;
}

.AutoSizerWrapper {
  flex: 1 1 auto;
  min-height: 100vh;
}

.small-label .MuiFormControlLabel-label {
  font-size: 12px;
  /* Adjust the size as needed */
}

.title-back-container {
  display: flex;

}


.document-title-container {
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  flex: 1;
  /* margin-left: 150px; */
}

.download-upperTable-container {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  z-index: 20;
}

.document-Dense-container {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 20;
  gap: 50px;
  margin: 20px 0;
}

.check_actions_container {
  display: flex;
  flex-direction: column;
}

.actions_container {
  display: flex;
  margin-top: 10px;
}

.searchFilter-bar__container {
  margin-top: 10px;
  display: flex;
  gap: 40px;
}

.monthYearInlineStyle {
  width: 100%;
  height: 100%;
}

.form-container {
  width: 100%;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  /* gap: 50px; */
}

.edit-form-row-item {
  flex: 1;
  min-width: 250px;
  padding: 0 15px;
  /* Adjust as needed */
}

.edit-form-row-item-title {
  color: var(--black-90, rgba(0, 0, 0, 0.90));
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
  margin-bottom: 8px;
  margin-top: 8px;
  /* Add some spacing below the title */
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.edit-cancel-button {
  width: 20%;
  height: 40px;
  background: var(--white, white);
  border-radius: var(--Spacing-md, 8px);
  border: 2px solid var(--orange, #F9AA2A);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
  color: var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A));
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  align-content: center;
}

.edit-cancel-button:hover {
  background-color: var(--orange, #F9AA2A);
  color: #FFF;
}

.edit-save-button {
  width: 20%;
  height: 40px;
  margin-right: 8px;
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  color: var(--white, #FFF);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  align-content: center;
}

.edit-save-button:hover {
  background-color: #2037CC;
}

.edit-title {
  color: var(--black-90, rgba(0, 0, 0, 0.90));
  text-align: center;
  border-radius: var(--Spacing-md, 8px);
  background: var(--foundation-blue-light-active, #BFC8FF);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  min-width: 80px;
  /* min-height: var(--Spacing-xl-section, 48px); */
  padding: var(--Spacing-xl, 8px) var(--Spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.newDocument-button {
  border-radius: var(--Spacing-md, 8px);
  background: var(--foundation-blue-light-active, #BFC8FF);
  display: flex;
  width: 200px;
  height: var(--Spacing-big-size, 24px);
  padding: var(--Spacing-xl, 16px) var(--Spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  color: var(--Drak-80, rgba(0, 0, 0, 0.80));
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.newDocument-button.active {
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  color: var(--white, #FFF);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.newDocument-container-button {

  display: flex;
  gap: 20px;
}

.newDocument-button:hover {
  background: var(--foundation-blue-normal, #304FFF);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.modal-content-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}

.newDocument-autoContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  margin-top: 20px;
}

.newDocument-vatSelect {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
}

.newDocument-file-upload-container {
  border-radius: var(--Spacing-md, 8px);
  background: var(--bg-input, #F5F5F6);
  display: flex;
  width: 90%;
  height: 25px;
  padding: var(--Spacing-big-size, 64px) var(--Spacing-xxl, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-xxl, 24px);
  border: 2px dashed #d5d5d5; /* Small dotted border */
  background: var(--bg-input, #F5F5F6);
}



.input-file {
  display: none;
  /* Hide the actual file input */
}

.newDocument-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.upload-icon {
  width: 58px;
  height: 58px;
}

.upload-text {
  color: var(--Drak-80, rgba(0, 0, 0, 0.80));
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.newDocument-create-buttons {
  border-radius: var(--Spacing-md, 8px);
  background: var(--Foundation-Blue-Normal, #304FFF);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 20%;
  height: 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white, #FFF);
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.newDocument-cancel-buttons {
  border-radius: var(--Spacing-md, 8px);
  border: 2px solid var(--orange, #F9AA2A);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 20%;
  height: 35px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A));
  text-align: center;

  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.newDocument-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
}

.newDocument-create-buttons,
.newDocument-cancel-buttons {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newDocument-create-buttons:hover {
  background-color: #0062cc;
  /* Hover color */
  color: white;
}

.newDocument-cancel-buttons:hover {
  background-color: #d9534f;
  /* Hover color */
  color: white;
}

.newDocument-create-buttons[disabled],
.newDocument-create-buttons.disabled,
.edit-save-button[disabled],
.edit-save-button.disabled {
  background-color: #ccc;
  /* Disabled background color */
  color: #888;
  /* Disabled text color */
  cursor: not-allowed;
}

.newDocument-create-buttons:not([disabled]):hover {
  background-color: #0056b3;
  /* Active hover color */
}

.newDocument-cancel-buttons:hover {
  background-color: #ff6666;
  /* Hover color for cancel button */
}

.newDocument-container-items {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.newDocument-form-row-item {
  width: 100%;
  /* Adjust as needed */
  margin-top: 10px;
}

.document-download-buttons,
.document-download-cancel-buttons {
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
  border: none;
  display: inline-block;
  width: 20%;
}

.document-download-buttons {
  background: #304FFF;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.document-download-buttons:hover {
  background-color: #003cb3;
}

.document-download-cancel-buttons {
  background: transparent;
  border: 2px solid #F9AA2A;
  color: #F9AA2A;
}

.document-download-cancel-buttons:hover {
  background-color: #F9AA2A;
  color: white;
}

/* Default style for description cells */
.description-cell {
  padding: 8px;
  text-align: center;
}

/* Flickering effect for duplicate descriptions */
.flickering-cell {
  background-color: red !important;
  color: white;
  animation: flickerAnimation 1s infinite alternate;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}