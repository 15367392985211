.add-client-input-style {
    /* padding: 8px 8px 8px 40px; */
    border-radius: var(--Spacing-md, 8px);
    background: var(--bg-input, #F5F5F6);
    color: #a9a9a9;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 24px;
    outline: none;
    width: 100%;
    height: 48px;
    max-width: 400px;
    /* Remove text-align to allow dynamic alignment */

}

.add-client-form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.form-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.form-row-2 {
    display: flex;
    flex-direction: row-reverse;
}

.form-row-item {
    display: flex;
    flex-direction: column;
    width: 40%;
}


.form-row-item-title {
    color: var(--black-90, rgba(0, 0, 0, 0.90));
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: flex-start;

}

.custom-label {
    color: black !important;
    /* Red color when not focused */
}

.custom-label.Mui-focused {
    color: black !important;
    /* Default color on focus */
}

.button-disabled {
    background-color: #ccc;
    /* Gray background */
    cursor: not-allowed;
    /* Not-allowed cursor */
    opacity: 0.6;
    /* Reduced opacity */
    pointer-events: none;
    /* Prevents clicking */
}


/* Ensure the dropdown menu itself also follows border-box */
.muirtl-mo1wn4-control {
    /* React-Select menu */
    box-sizing: border-box !important;
}