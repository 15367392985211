body,
h1,
h2,
table {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.krtest-titles {
    width: 400px;
    height: 100px;
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
}

.table-header-cell {
  transition: background-color 0.3s ease; /* Optional: Smooth transition for hover effect */
}

.table-header-cell:hover {
  background-color: #f0f0f0; /* Change this to your desired hover color */
  cursor: pointer;
}

.table-headers-accountcard {
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Optional: Smooth transition for hover effect */
}

.table-headers-accountcard:hover {
  background-color: #e1e1e1; /* Change this to your desired hover color */
}