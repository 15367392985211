.tooltip-container {
    /* position: relative; */
    display: inline-block;
    width: 100%;
}

/* Common Tooltip Style */
.tooltip {
    position: absolute;
    background-color: rgb(0, 0, 0);
    /* Dark background for readability */
    color: white;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    white-space: nowrap;
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    z-index: 10000;
}

/* Tooltip for VAT Equipment */
.tooltip-equipment {
    top: 180px;
    /* Position above the input */
    right: 30px;
    transform: translateX(-50%);
}

/* Tooltip for VAT Expenses */
.tooltip-expenses {
    top: 250px;
    /* Position above the input */
    right: 30px;
    transform: translateX(-50%);
}

/* Tooltip for VAT Transactions */
.tooltip-transactions {
    top: 120px;
    /* Position above the input */
    right: 30px;
    transform: translateX(-50%);
}

/* Hover Effect - Show Tooltip */
.tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-5px);
}

/* VAT Breakdown Section */
.vat-breakdown {
    margin-top: 20px;
    padding-top: 10px;
    width: 100%;
    text-align: center;
    border-top: 1.5px solid #ccc;
}

.vat-breakdown h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
    text-transform: uppercase;
}

/* VAT Breakdown Table */
.vat-breakdown-table {
    margin: 0 auto;
    width: 60%;
    border-collapse: collapse;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    direction: ltr;
}

.vat-breakdown-table thead {
    background-color: #f5f5f5;
}

.vat-breakdown-table th {
    padding: 6px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    border-bottom: 1.5px solid #ddd;
    text-align: center;
}

.vat-breakdown-table td {
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #eee;
    text-align: center;
    color: #444;
}

.vat-breakdown-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.vat-breakdown-table tr:hover {
    background-color: #f1f1f1;
    transition: 0.2s ease-in-out;
}

/* A4 Landscape Optimization */
@media print {
    .vat-breakdown-table {
        width: 100%;
        font-size: 10px;
    }

    .vat-breakdown h3 {
        font-size: 14px;
    }

    .vat-breakdown-table th,
    .vat-breakdown-table td {
        padding: 4px;
    }
}
