.login {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: red;
}

body[dir="rtl"] .login-input-container .MuiInputAdornment-root {
  order: -1;
  /* Make the icon appear on the left side */
}



.h2-header {
  color: #5d5d5b;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 18px;
  text-align: center;
  margin-top: 10px;

}


.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me input {
  margin-right: 5px;
}

/* .login-container a {
  color: #007bff;
  text-decoration: none;
} */

.login-logo {
  width: 185px;
  height: 220px;
  background-image: url(../../assests/images/logo.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* margin-top: 20px; */
}

.login-button {
  width: 100%;
  /* height: 20%; */
  padding: 0px 8px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
  background: #304FFF;
  margin-top: 20px;
  cursor: pointer;
  color: var(--background, #FFF);
  height: 66px;
  font-family: Poppins;
  font-size: 27px;
  font-style: normal;
  font-weight: 300;
}

.login-button:hover {
  background-color: #F9AA2A;
  transition: background-color 0.3s ease;
}

.sendOtpButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-horizontal-container {
  display: flex;

  flex-direction: row;
  margin-top: 20px;
}

.divider-horizontal {

  width: 202px;
  /* height: 2px; */
  /* background-color: #d3d3d3; */
  /* background-color: 'red'; */
  margin-right: 15px;
  border: 1px solid #d3d3d3;
}

.text-input {
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  margin-top: 15px;
}

.forget-input-container {
  width: 100%;
  /* Makes the container take up the full width */
  display: flex;
  justify-content: space-between;
  /* Allows alignment of children using justify-content */
}

.forget-input {
  color: var(--foundation-yellow-normal-hover, #E09926);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  padding: 8px;
  /* Adding padding to make the button more user-friendly to click */
}

.register-client-input {
  color: var(--foundation-yellow-normal-hover, #304FFF);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 'bold';
  font-weight: bolder;
  text-decoration-line: underline;
  cursor: pointer;
  padding: 8px;
  /* Adding padding to make the button more user-friendly to click */
}

.style-input {
  border-radius: var(--Spacing-md, 8px);
  background: var(--bg-input, #F5F5F6);
}

/* Specific styles for the password input to match length with other input */
.password-input .MuiFilledInput-root {
  padding-right: 0;
  /* Remove extra padding on the right */
}

.password-input .MuiInputAdornment-root {
  margin-left: 0;
  /* Remove any extra margin introduced by the adornment */
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  margin-top: 20px;
}

.login-input-container {
  margin-bottom: 16px;
  /* Add spacing between inputs */
}

.login-input-label {
  color: var(--black-90, rgba(0, 0, 0, 0.9));
  text-align: right;
  font-family: var(--text-18semi-font-family,
      "Montserrat-SemiBold",
      sans-serif);
  font-size: var(--text-18semi-font-size, 16px);
  font-weight: var(--text-18semi-font-weight, 600);
  position: relative;
  margin-bottom: 10px;

}

.error-message {
  color: red;
  margin-top: 10px;
}

.login-backIcon:hover {
  transform: scale(1.1);
}

.login-backIcon {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  /* background-color: black; */
  align-self: flex-start;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.login-container {
  display: flex;
  height: 100vh;
  /* Full height of the viewport */
}

.login-form-container {
  flex: 1;
  /* Takes half the width */
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #ffffff;
  /* Optional: background color for the form side */
}

.login-form-subContainer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-left: 70px;
  padding-right: 70px;
}

.login-form {
  width: 100%;
  margin-top: 30px;
  /* Control the form width */
}

.login-image-container {
  flex: 1;
  /* Takes half the width */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* Ensures that the image fills the container without causing scrollbars */
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Cover the entire container */
  object-position: center;
  /* Center the image */
}




.logo-ltr {
  position: absolute;
  top: 3%;
  right: 4%;
  width: 15vw;
  height: auto;
  max-width: 110px;
  max-height: 200px;
  z-index: 1;
}

.logo-rtl {
  position: absolute;
  top: 3%;
  left: 4%;
  width: 15vw;
  height: auto;
  max-width: 110px;
  max-height: 200px;
  z-index: 1;
}

.verify-otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.animations-container {
  margin-bottom: 20px;
}

.receive-6-digit-code {
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
}

.otp-inputs-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.verify-button {
  background-color: rgba(249, 170, 42, 1);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.highlight-text {
  color: var(--Foundation-Yellow-Normal, #F9AA2A);

  /* text-24semi */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.code-text {
  color: var(--black-90, rgba(0, 0, 0, 0.90));
  text-align: center;

  /* text-24semi */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.password-details {
  color: var(--Dark-56, rgba(0, 0, 0, 0.56));
  text-align: center;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.DidNotReceiveCode-button {
  color: var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A));

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.resend-otp-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.toggle-button-group {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.user-type-toggle .MuiToggleButton-root {
  border-radius: 20px;
  text-transform: none;
}

.user-type-toggle .Mui-selected {
  background-color: #263fcc !important;
  color: white !important;
}

@media (max-width: 768px) {
  .logo-image {
    width: 20vw;
    /* Make the logo smaller for tablet and smaller devices */
  }
}

@media (max-width: 480px) {
  .logo-image {
    width: 25vw;
    /* Make the logo smaller for mobile devices */
  }
}

@media (max-width: 768px) {

  /* .login-container {
    width: 70%;
    padding: 20px;
    height: auto;

  } */
  .loginSvgCorner {
    display: none;
  }

  .login-logo {
    width: 150px;
    /* Scale down the logo */
    height: 150px;
    /* Scale down the logo */
  }

  .h2-header {
    font-size: 12px;
    /* Reduce font size for smaller screens */
  }

  .style-input,
  .login-button,
  .input-container {
    width: 100%;
    /* Full width for inputs and button */
    height: 50px;
    /* Reduce the height of the input fields and button */

  }

  .input-container {
    align-items: center;
    /* Center the input fields if needed */
  }

  .text-input {
    font-size: 14px;
  }

  .login {
    width: 100%;
  }

  .divider-horizontal-container {
    width: 100%;
  }
}