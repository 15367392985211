/* CreateInvoice.css */

.draft-pdf-modal-container {
    position: relative;
    width: 80%;
    height: 90vh;
    margin: 5vh auto;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.draft-pdf-embed {
    flex: 1;
    width: 100%;
    height: calc(100% - 80px);
    /* Adjust height to account for button container */
    border: none;
}

.draft-pdf-button-container {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 20px;
    background-color: #f5f5f6;
}

.create-invoice-button {
    background-color: #304FFF;
    padding: 10px 20px;
    width: 30%;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: var(--white, #FFF);
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.create-invoice-button.button-disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.close-invoice-button {
    background-color: white;
    padding: 10px 20px;
    width: 30%;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: var(--white, #F9AA2A);
    text-align: center;
    border: 2px solid var(--orange, #F9AA2A);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: background-color 0.3s, color 0.3s;
}

.close-invoice-button:hover {
    background-color: var(--orange, #F9AA2A);
    color: #FFF;
}

.close-invoice-button.button-disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.draft-invoice-close-button {
    background-color: transparent;
    color: #F9AA2A;
    width: 30%;
    padding: 10px 20px;
    border: 1px solid #F9AA2A;
    border-radius: 8px;
    cursor: pointer;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.createInvoice-amount-button {
    background-color: transparent;
    color: #F9AA2A;
    width: 30%;
    /* padding: 10px 20px; */
    border: 1px solid #F9AA2A;
    border-radius: 8px;
    cursor: pointer;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.createInvoice-amount-button:hover {
    background-color: #304FFF;
    transition: background-color 0.3s ease;
}

.create-invoice-button:hover {
    background-color: #0062cc;
    color: white;
}

.draft-invoice-close-button:hover {
    background-color: #d9534f;
    color: white;
}

.create-invoice-button,
.draft-invoice-close-button {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.Receipt_PAitems_Container {
    width: 80%;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    direction: rtl;
    background-color: #faf9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PA-form-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}