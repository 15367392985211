.template-page {
    display: flex;
    min-height: 100vh;
    /* Full viewport height */
    /* padding: 100px 20px; */
    /* Adjust padding as necessary */
    gap: 20px;
    background: #FCFCFF;
    /* padding-top: 150px; */
}

.template-page-withoutHeight {
    display: flex;
    position: relative;
    /* padding: 100px 20px; */
    /* Adjust padding as necessary */
    gap: 10px;
    background: #FCFCFF;
    /* padding-top: 150px; */
}

.template-container {
    flex-grow: 1;
    /* Grow to use available space */
    /* margin-right: 200px; */
    /* Adjust the right margin to prevent touching the hamburger menu */
    padding: 20px 100px;
    overflow-y: auto;
    /* Allows for scrolling within the container */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.filtersBar {
    display: flex;
    align-items: center;
    /* This will place the language selector on the left and search bar on the right */
    margin-bottom: 20px;
    gap: 40px;
}

.hamburger-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 250px;
    /* Width of your HamburgerMenu */
    height: 100vh;
    background: #ffffff;
    /* or any color you prefer */
    /* Other styles for the HamburgerMenu */
}



.search-container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-grow: 1;
    padding: 0 20px;
}


.search-bar {
    /* padding: 8px 8px 8px 40px; */
    border-radius: var(--Spacing-md, 8px);
    background: var(--bg-input, #F5F5F6);
    color: #a9a9a9;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 24px;
    outline: none;
    width: 100%;
    height: 48px;
    max-width: 300px;
    /* Remove text-align to allow dynamic alignment */
}




.rtl-text-align {
    direction: rtl;
    /* For RTL languages */
}

.client-info-value {
    direction: ltr;
    /* Ensures numbers and mixed content are displayed left-to-right */
}

.title-and-filter {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    /* For overall positioning */
    padding: 20px;
}

.folders-title {
    margin: 0;
    color: #030303;
    font-size: 20px;
    /* font-family: "Poppins"; */
    /* font-weight: 600; */
    /* line-height: 32px; */
    flex-shrink: 0;
}

.client-info-text {
    color: #025366;
    font-size: 22px;
    /* font-family: "Poppins";
    font-weight: 600;
    line-height: 24px; */
    text-align: center;
    font-weight: 400;
    white-space: wrap;
    /* Prevent text from wrapping */
    width: 100%;
    overflow-wrap: break-word;
    /* Hide overflowing text */
    text-overflow: ellipsis;
}

.client-info-case-text {
    color: #E57C22;
    font-size: 22px;
    /* font-family: "Poppins";
    font-weight: 600;
    line-height: 24px; */
    text-align: center;
    font-weight: 600;
}

.folder-info-details {
    display: flex;
    flex-direction: column;
    height: 100px;
    word-break: break-all;
    /* Ensure aggressive line breaking */
    width: 100%;
    /* Make sure it takes the full width of the container */
    box-sizing: border-box;
    /* Include padding and border in element's total width and height */
    margin-top: 10px;


}

.client-info-text,
.client-info-case-text {
    white-space: normal;
    overflow-wrap: break-word;
    display: block;
    /* Ensures the text elements are block-level to wrap properly */
    width: 100%;
    /* Ensures the element takes up the full width of the container */
    word-break: break-all;
    /* Another property to ensure proper wrapping */
}


.filter-button {
    min-width: 235px;
    min-height: 50px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 9px;
    background: var(--background, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    text-align: center;
    /* font-family: Poppins; */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    outline: none;
    cursor: pointer;
}

.filter-button:hover {
    transform: scale(1.05);
}

.folders-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 20px; */
    margin-bottom: 20px;

}

.disableUser {
    background: #7b7b7b !important;
}

.folder-card {
    height: auto;
    width: 150px;
    /* min-height: 250px; */
    flex-shrink: 0;
    padding: 10px 20px 10px 20px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
    border-radius: 16px;
    background: #F4EDED;
    box-shadow: 0px 1px 4px 0px #E57C22;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s ease;

}


.filter-button-select {
    width: 235px;
    height: 37px;
    /* padding: 0px 8px;
    border: 0; */
    box-sizing: border-box;
    border-radius: 9px;
    background: var(--background, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    color: black;
    /* font-family: "Roboto"; */
    outline: none;
    cursor: pointer;
    font-size: 18px;
}



.folder-thumbnail {
    /* width: 200px; */
    height: 100px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.folder-card:hover .folder-thumbnail {
    transform: scale(1.2) rotate(10deg) translateY(-10px);
    /* Scale the image up, rotate, and move up on hover */
}

.folder-card:hover {
    transform: scale(1.1);
    /* Scale the image up and rotate on hover */
}

.folder-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.view-button {
    width: 112px;
    height: 51px;
    padding: 5px 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 3px;
    background: #025366;
    cursor: pointer;
    margin-top: 10px;
    color: var(--background, #FFF);

    text-align: center;
    /* font-family: Poppins; */
    font-size: 20px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: 16px;
    /* 80% */
}

.view-button-div {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
}

.view-button:hover {
    transform: scale(1.1);
}

/* .view-button-rtl { */
/* order: -1; */
/* Place the button on the left */
/* text-align: right; */
/* Align text to the right for RTL */
/* } */

.load-more {
    width: 100%;
    width: 253px;
    height: 48px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 25px;
    box-shadow: 0px 0px 10px rgba(3, 3, 3, 0.1);
    background-color: #030303;
    color: #ffffff;
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 20px;
    outline: none;
    margin-top: 20px;
    cursor: pointer;
}




.load-more.disabled {
    color: #ccc;
    /* Example color for disabled state */
    background-color: #f5f5f5;
    /* Light grey background for disabled state */
    cursor: default;
    /* Cursor style indicating the button is not clickable */
    /* Additional styling for disabled state */
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    width: 100vw;
    /* Full viewport width */
    /* background-color: rgba(0, 0, 0, 0.364); */
    position: fixed;
    /* Fixed position */
    top: 0;
    left: 0;
    z-index: 10000;
    /* High z-index to ensure it's above other elements */
    backdrop-filter: blur(10px);
}

.loader-container2 {
    position: fixed;
    /* Allows the overlay to stay in place even if the page scrolls */
    top: 0;
    left: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
    /* A semi-transparent overlay background */
    display: flex;
    /* Centers the loader horizontally & vertically */
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Ensure the loader appears above other elements */
}

.loader-photo {
    width: 300px;
    height: 300px;
}

.folder-card.highlight-border {
    border: 2px solid red;
}

.notification-count {
    position: absolute;
    /* Positioning relative to the folder-card */
    top: 0;
    /* Align to the top */
    right: 0;
    /* Align to the right */
    transform: translate(50%, -50%);
    /* Adjust to precisely position the circle */
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0 5px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    min-width: 20px;
}

.main-clientsNumber {
    width: 178px;
    height: 80px;
    border-radius: 5px;
    background: var(--background, #FFF);
    /* Positioning relative to the parent */
    left: 50%;
    /* Center based on all available space */
    /* Ensure it's centered accurately */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clientsNumber-title {
    color: var(--foreground, #000);
    /* font-family: Poppins; */
    font-size: 20px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: normal;
    margin-top: 10px;

}

.clientsNumber-Container-number {
    width: fit-content;
    min-width: 34px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 3px;
    background: #025366;
    margin-top: 5px;
    text-align: center;

}

.clientsNumber-number {
    color: #E57C22;
    /* font-family: Poppins; */
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.main-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px
}

.select-done-container {
    display: 'flex';
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 40px;
    border-radius: 3px;
    background: #025366;
    margin: 0 auto;
    text-align: center;

}

.select-done-button {
    width: 50px;
    height: 40px;
    align-items: center;
    border-radius: 3px;
    background: #025366;
    border: none;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;

}

.select-done-text {
    color: #E57C22;
    font-family: Poppins;
    text-align: center;
    font-size: 16px;
}

.main-title {
    color: var(--Foundation-Blue-Darker, #111C59);
    /* text-40semi */

    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* margin-top: 40px; */
}

.main-title.rtl {
    text-align: right;
}

.main-title.ltr {
    text-align: left;

}

.main-title-black {
    color: black;
    /* text-40semi */

    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* margin-top: 40px; */
}

.main-title-black.rtl {
    text-align: right;
}

.main-title-black.ltr {
    text-align: left;

}

.main-cards {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    /* gap: 20px; */
}

.main-card-container {
    border-radius: var(--Spacing-md, 8px);
    background: var(--foundation-yellow-light-hover, #FEF2DF);
    display: flex;
    width: 200px;
    padding: var(--Spacing-xl, 16px) var(--Spacing-lg, 12px);
    flex-direction: row;
    align-items: flex-start;
    gap: var(--Spacing-xxl, 24px);
    margin: 0 16px 0 16px;
}

.main-card-icon {
    display: flex;
    width: var(--Spacing-xxl-section, 56px);
    height: var(--Spacing-xxl-section, 56px);
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 48px;
    background: var(--bg-tabbar, #FCFCFF);
    width: 36px;
    height: 36px;
}

.main-card-details {
    display: flex;
    flex-direction: column;
}

.main-card-details1 {
    color: #202224;
    opacity: 0.7;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.main-card-details2 {
    color: #202224;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.main-card-details2.rtl {
    text-align: right;
}

.main-card-details2.ltr {
    text-align: left;

}

.main-card-details1.rtl {
    text-align: right;
}

.main-card-details1.ltr {
    text-align: left;

}

.addClient-button {
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #304FFF);
    width: 180px;
    height: var(--Spacing-xl-section, 48px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addClient-button span {
    color: var(--white, #FFF);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}

.addClient-button:hover {
    background-color: #F9AA2A;
    transition: background-color 0.3s ease;
}

.addClient-button-2 {
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #304FFF);
    width: 150px;
    height: var(--Spacing-xl-section, 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-size: medium;
}

.addClient-button-2 span {
    color: var(--white, #FFF);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}

.addClient-button-2:hover {
    background-color: #F9AA2A;
    transition: background-color 0.3s ease;
}


.search-bar .MuiFilledInput-root {
    padding-right: 0;
    /* Remove extra padding on the right */
}

.search-bar .MuiInputAdornment-root {
    margin-left: 0;
    /* Remove any extra margin introduced by the adornment */
}

.main-processed {
    border-radius: var(--Spacing-big-size, 16px);
    /* Adjust the size for better fit */
    background: rgba(192, 57, 43, 0.12);
    display: flex;
    height: 24px;
    /* Decreased height */
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* Adjusted gap for spacing */
    color: var(--Warning, #C0392B);
    min-width: 40px;
    /* Adjusted minimum width */
    margin-top: 5px;
    /* Reduced margin */
    font-size: 16px;
    /* Decreased font size */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 8px;
    /* Added padding for better spacing */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Ensure no overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowed text */
}

.main-unProcessed {
    border-radius: var(--Spacing-big-size, 16px);
    /* Adjust the size for better fit */
    background: rgba(44, 216, 82, 0.20);
    display: flex;
    height: 24px;
    /* Decreased height */
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* Adjusted gap for spacing */
    color: var(--Success, #2CD852);
    min-width: 40px;
    /* Adjusted minimum width */
    margin-top: 5px;
    /* Reduced margin */
    font-size: 16px;
    /* Decreased font size */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 8px;
    /* Added padding for better spacing */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Ensure no overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowed text */
}

.main-warning {
    border-radius: var(--Spacing-big-size, 16px);
    /* Adjust the size for better fit */
    background: rgba(241, 196, 15, 0.30);
    /* Lightened yellow background for better contrast */
    display: flex;
    height: 28px;
    /* Slightly increased height for better clarity */
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* Adjusted gap for spacing */
    color: #8a6d00;
    /* Darkened text color for better contrast */
    min-width: 60px;
    /* Increased minimum width */
    margin-top: 5px;
    /* Reduced margin */
    font-size: 16px;
    /* Increased font size for better readability */
    font-style: normal;
    font-weight: 600;
    /* Increased font weight for emphasis */
    line-height: 1.2;
    /* Adjusted line height */
    padding: 0 12px;
    /* Added padding for better spacing */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Ensure no overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowed text */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for a clearer look */
}

/* Assignments - Red (Not Finished) */
.main-assignments {
    border-radius: var(--Spacing-big-size, 16px);
    /* Adjust the size for better fit */
    background: rgba(192, 57, 43, 0.15);
    /* Lightened red background for better contrast */
    display: flex;
    height: 28px;
    /* Slightly decreased height */
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* Reduced gap for spacing */
    color: #C0392B;
    /* Kept the same text color */
    min-width: 60px;
    /* Adjusted minimum width */
    padding: 0 12px;
    /* Added padding for better spacing */
    margin-top: 5px;
    /* Reduced margin */
    font-size: 16px;
    /* Adjusted font size for clarity */
    font-style: normal;
    font-weight: 600;
    /* Increased font weight for emphasis */
    line-height: 1.2;
    /* Adjusted line height */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Ensure no overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowed text */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for a clearer look */
}

/* Assignments - Green (Finished) */
.main-assignments-notfinished {
    border-radius: var(--Spacing-big-size, 16px);
    /* Adjust the size for better fit */
    background: rgba(44, 216, 82, 0.25);
    /* Lightened green background for better contrast */
    display: flex;
    height: 28px;
    /* Slightly decreased height */
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* Reduced gap for spacing */
    color: #2CD852;
    /* Kept the same text color */
    min-width: 60px;
    /* Adjusted minimum width */
    padding: 0 12px;
    /* Added padding for better spacing */
    margin-top: 5px;
    /* Reduced margin */
    font-size: 16px;
    /* Adjusted font size for clarity */
    font-style: normal;
    font-weight: 600;
    /* Increased font weight for emphasis */
    line-height: 1.2;
    /* Adjusted line height */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Ensure no overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowed text */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for a clearer look */
}

.client-main-table-container {
    height: 50vh;
    width: 100%;
    box-sizing: border-box !important;
}

@media (min-width: 768px) {
    .main-clientsNumber {
        position: absolute;
        transform: translateX(-50%);

    }

    .folders-title {
        margin-left: 50px;
    }
}

@media (max-width: 768px) {
    .template-page {
        /* Full viewport height */
        display: flex;
        justify-content: space-between;
        /* Adjust padding as necessary */
        gap: 20px;
        margin-top: 40px;
        /* Space between content and hamburger menu */
    }

    .template-container {
        margin-right: 0;
        /* Content can take full width on smaller screens */
        padding: 20px;
    }

    .search-and-language,
    .title-and-filter,
    .folders-grid,
    .load-more {
        padding: 0 10px;
        /* Adjust padding for smaller screens */
    }

    .folders-title {
        margin-top: 50px;
    }

    .title-and-filter {
        text-align: center;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .folders-grid {
        grid-template-columns: 1fr;
        /* Single column layout on smaller screens */
    }



    .folder-card {
        margin: 0 auto;
        margin-top: 25px;
    }
}