.filterMenuWrapper {
    position: relative; /* Relative positioning for the wrapper */
    display: inline-block; /* Keeps the filter and menu together */
}

.filterOptionContainer {
    display: flex;
    width: var(--Spacing-xl-section, 48px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Spacing-md, 8px);
    background: var(--foundation-blue-light-hover, #E0E5FF);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.filterOptionContainer:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transform: scale(1.05);
}

.menu {
    position: absolute; /* Position the menu absolutely within the wrapper */
    top: 100%; /* Align it directly below the filter button */
    z-index: 1000; /* Ensure it appears above other elements */
    display: flex;
    width: 283px;
    padding: var(--Spacing-xl, 16px) var(--Spacing-lg, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-xl, 16px);
    border-radius: var(--Spacing-md, 8px);
    background: var(--white, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.32);
    margin-top: 8px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: scaleY(0);
}

.menu--open {
    opacity: 1;
    transform: scaleY(1);
}

.menu--close {
    opacity: 0;
    transform: scaleY(0);
}

.option-group {
    width: 100%;
}

.group-title {
    color: var(--black-90, rgba(0, 0, 0, 0.90));
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
}

.menu-item {
    display: flex;
    align-items: center;
    gap: var(--Spacing-sm, 4px);
    height: 28px;
    padding: var(--Spacing-sm, 4px);
    align-self: stretch;
    border-radius: var(--Spacing-sm, 4px);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.menu-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transform: translateX(5px);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.menu-item.unselected {
    background: var(--secondary-12, rgba(254, 247, 234, 0.12));
}

.menu-item.selected {
    background: var(--orange, #F9AA2A);
    color: white;
}
