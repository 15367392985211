.ac-template-page-clients {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    background: #FCFCFF;
}

.ac-client-details-container {
    flex-grow: 1;
    /* Grow to use available space */
    margin-right: 270px;
    /* Adjust the right margin to prevent touching the hamburger menu */
    padding: 20px 0 20px 20px;
    overflow-y: auto;
    /* Allows for scrolling within the container */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.ac-action-card {
    width: 40px;
    /* Adjust the size as needed */
    height: 40px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ac-client-arrow {
    filter: invert(100%);
    /* Invert the color to white */
    width: 20px;
    height: 20px;
}

.ac-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* This will push the button to the left and the title container to the right */
    margin-bottom: 20px;
}

.ac-header-right {
    display: flex;
    align-items: center;
}

.ac-main-card-title-edit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.ac-date-picker .ac-date-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    background-color: #fff;
    margin-right: 10px;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    width: 145px;
    height: 39px;
    text-align: center;
}

.ac-date-picker:first-child button {
    cursor: pointer;
}

.ac-custom-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    width: 145px;
    height: 39px;
    text-align: center;
    /* Make sure to adjust these styles so that the button looks like a typical input field */
}

.ac-title-text {
    color: #030303;
    font-size: 48px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.ac-download-icon {
    /* Style as needed */
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
}

.ac-style-icon-download {
    height: 30px;
    /* or any size that fits your design */
    width: 30px;
    /* Maintain aspect ratio */
    /* Additional styles if needed */
}



.ac-card-content {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.ac-client-photo {
    /* Add path to client photo and style as needed */
    width: 100.364px;
    height: 92px;
    margin: 0 auto;
}





.ac-financial-cards {
    display: flex;
    /* width: 100%; */
    justify-content: flex-start;
    gap: 20px;
    margin-top: 10px;
    flex-wrap: wrap;
}

.ac-financial-card {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background: #025366;
    cursor: pointer;
    justify-content: space-around;
    gap: 20px;
}

.ac-financial-card.ac-large {
    flex: 2;
    flex-direction: row;
    /* Align items horizontally */
    align-items: center;
    /* Center items vertically */
    gap: 30px;
    /* Space between the icon and the text */
    /* Other styles remain the same */
}

.ac-financial-info {
    /* flex: 1; */
    /* Takes up one part */
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
}

.ac-financial-info .ac-financial-card {
    flex: 1;
    /* Each card takes up an equal amount of space */
}

.ac-card-icon {
    /* Style your icons appropriately */
    margin-bottom: 10px;
    /* Add space between icon and title */
    height: 60px;
    /* Example size, adjust as needed */
    width: 60px;
    /* Example size, adjust as needed */
}

.ac-financial-title {
    color: var(--background, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    /* Space between title and amount */
}

.ac-financial-title-profit {
    text-align: start;
}

.ac-financial-amount {
    color: #E57C22;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.ac-financial-circle-amount {
    min-width: 136px;
    min-height: 136px;
    width: auto;
    /* Ensure width can adjust based on content */
    height: auto;
    /* Ensure height can adjust based on content */
    padding: 10px;
    /* Adjust padding as needed */
    border-radius: 50%;
    background-color: #FCFCFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* Ensures text is centered if it wraps */
    overflow: hidden;
    /* Prevents content from spilling outside the circle */
    white-space: nowrap;
    /* Prevents text from wrapping */
}


.ac-financial-amount-profit {
    color: #030303;
    font-size: 48px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 48px;
}

.ac-action-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    cursor: pointer;
}

.ac-action-card {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 500px;
    height: 272px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ac-card-icon {
    width: 60px;
    height: 60px;
}

.acA-card-icon {
    width: 50;
    height: 40px;
}

.acC-card-icon {
    width: 50;
    height: 38px;
}

.ac-card-label {
    font-size: 1em;
}

.ac-main-card {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    /* Pushes the card down from the top */
    margin-left: auto;
    /* Centers the card horizontally */
    margin-right: auto;
    /* Centers the card horizontally */
    /* height: 221px; */
    border-radius: 10px;
    background: #025366;
    width: 100%;
}

.ac-main-card-prop {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    /* Pushes the card down from the top */
    margin-left: auto;
    /* Centers the card horizontally */
    margin-right: auto;
    /* Centers the card horizontally */
    /* height: 221px; */
    border-radius: 10px;
    background: #025366;
    width: 100%;
    min-height: 50vh;

}

.ac-main-card-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    /* align-self: center; */
}


.ac-input-field-client {
    /* height: 40px; */
    margin: 10px 0;
    border: 0.5px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: 'black';
    background-color: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 80%;
    /* Full width */
    text-align: center;
    box-sizing: border-box;
    height: 40px;
    /* Fixed height */
    overflow: auto;
    /* Allows scrolling inside the input */
    white-space: nowrap;
    /* Keeps the text in a single line */
}

.ac-input-field-client-prop {
    margin: 10px 0;
    border: 0.ac-5px solid #ddd;
    padding: 0px;
    border-radius: 5px;
    font-size: 16px;
    color: black;
    background-color: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.ac-2);
    width: 220px;
    text-align: center;
    box-sizing: border-box;
    height: 40px;
    /* Fixed height */
    /* Allows scrolling inside the input */
    white-space: nowrap;
    /* Keeps the text in a single line */
    margin-right: 10px;
    direction: rtl;
}

.ac-info-card-text {
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides text that overflows the container */
    text-overflow: ellipsis;
    /* Adds an ellipsis to indicate there's more text */
    width: 100%;
    /* Adjust width as needed */
    /* padding: 0 10px; Optional padding */
    margin-top: 10px;
    color: var(--foreground, #000);

    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

.ac-info-card-text-title {
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides text that overflows the container */
    text-overflow: ellipsis;
    /* Adds an ellipsis to indicate there's more text */
    width: 100%;
    /* Adjust width as needed */
    /* padding: 0 10px; Optional padding */
    /* margin-top: 10px; */
    color: var(--background, #FFF);

    text-align: center;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
}

.ac-main-card-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ac-client-photo-container {
    width: 80px;
    height: 80px;
}

.ac-input-field-dropdown-Client {
    /* padding: 0.ac-5rem; */
    /* margin-right: 1rem; */
    width: 100%;
    height: 40px;
    margin: 10px 0;
    /* padding: 10px; */
    border-radius: 5px;
    font-size: 12px;
    color: 'black';
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
}

.ac-input-field-dropdown-Client-sortCodeGroup {
    /* padding: 0.ac-5rem; */
    /* margin-right: 1rem; */
    width: 100%;
    height: 40px;
    padding: 10px;
    /* padding: 10px; */
    border-radius: 5px;
    font-size: 16px;
    color: 'black';
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
}

.ac-container-input-field-client-prop {
    display: flex;
    justify-content: space-around;
}

.ac-info-card-title-addButtons {
    display: flex;
    justify-content: space-between;
    /* Space between title and button */
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.ac-constants-card-upper {
    display: flex;
    justify-content: space-between;

    background-color: #025366;
    /* Space between title and button */
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 7px 7px 0 0;
    margin-top: 30px;
}

.ac-info-cards {
    display: flex;
    flex-grow: 1;
    /* Take remaining space */
    justify-content: space-evenly;
    /* Align cards to the start of the container */
    gap: 20px;
    width: 80%;
    /* Adjust the gap between cards */
    flex-direction: column;
}

.ac-info-cards-prop {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
    /* gap: 20px; */
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.ac-info-card-add-button {
    /* Keeps the button to the right */
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}

.ac-info-card {
    /* Style as needed, ensure they have a defined width */
    /* Do not grow or shrink */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    height: 96px;
    border-radius: 7px;
    background: var(--background, #FFF);
    margin-right: 20px;

}

.ac-info-card-Prop {

    min-height: 100px !important;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 40px;
    display: flex;
    border-radius: 0 0 7px 7px;
    background: var(--background, #FFF);
    /* margin-right: 20px; */
    padding: 20px;
}


.ac-info-card-Prop-Inputs-Container {

    min-height: 100px !important;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 40px;
    display: flex;
    border-radius: 0 0 7px 7px;
    background: var(--background, #FFF);
    /* margin-right: 20px; */
    padding: 20px;
    gap: 30px;
}

.ac-info-card-Prop-upper {

    /* width: 100% !important; */
    /* height: 50px !important; */
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 40px;
    display: flex;
    border-radius: 0 0 7px 7px;
    background: var(--background, #FFF);
    /* margin-right: 20px; */
    padding: 20px;
}

.ac-info-card-title {
    color: #030303;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 26px;
    text-align: center;
}

.ac-info-card-title-constants {
    color: #ffffff;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 26px;
    text-align: center;
}


.ac-report-title {
    color: #030303;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 26px;
    text-align: center;
}

.ac-info-card-content {
    color: #030303;
    font-size: 18px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.ac-info-card-title-prop {
    flex-grow: 1;
    color: var(--foreground, #000);
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin-left: 30px;
}

.info-card-title-prop-input-text {
    margin-left: 60px;
}

.ac-download-modal-overlay {
    display: flex;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.ac-5);
    justify-content: center;
    align-items: center;
}

.ac-download-modal {
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 300px;
    border-radius: 10px;
    text-align: center;
}

.ac-download-modal-content {
    background-color: white;
    margin: auto;
    padding: 20px;
    width: 80%;
    max-width: 300px;
    border-radius: 10px;
    text-align: center;
}

.ac-download-modal-buttons-container {
    display: flex;
    justify-content: space-around;

}

.ac-download-modal-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.ac-download-modal-button:hover {
    opacity: 0.ac-8;
}

.ac-client-back-icon {
    width: 40px;
    height: 40px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ac-title-container {
    width: 100%;
    /* This ensures that the title container takes up the remaining space */
    display: flex;
    justify-content: center;
    /* This will center the title text within the title container */
}

.ac-main-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.ac-info-card-container {
    display: flex;
    justify-content: space-between;
}

.ac-details-icon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.ac-details-icon:hover {
    transform: scale(1.05);
}


.container-input-field-ac-prop {
    display: flex;
    justify-content: space-between;
}

.ac-input-field-client-prop-sort {
    margin-left: 20px;
}

.expand-Width {
    width: 320px;
}

.hover-card {
    transition: background-color 0.3s ease, transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s ease;
}

.hover-card:hover {
    transform: scale(1.1);

}

.profile-accountant-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.profile-title {
    color: var(--Drak-80, rgba(0, 0, 0, 0.80));

    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.profile-actions {
    display: flex;
    gap: 8px;
}

.profile-full-width {
    width: 100% !important;
    margin-bottom: 20px;
}

.ac-properties-card {
    width: 40%;
    border-radius: var(--Spacing-xl, 16px);
    background: var(--white, #FFF);
    display: flex;
    flex-direction: column;
    padding: var(--Spacing-xxl, 24px) var(--Spacing-xl, 16px);
    gap: var(--Spacing-section, 32px);
    margin-top: 20px;
}

.ac-properties-card-title {
    color: var(--Foundation-Blue-Darker, #111C59);

    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ac-properties-card-title-button-container{
    display: flex;
    justify-content: space-between;
}

.ac-properties-card-container{
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.disabled-button {
    background-color: #d3d3d3; /* Light gray */
    color: #a0a0a0; /* Dim text */
    cursor: not-allowed;
    opacity: 0.6;
}
@media (max-width: 768px) {

    .ac-header,
    .ac-card-content,
    .ac-financial-cards,
    .ac-action-cards {
        flex-direction: column;
    }

    .ac-header-right {
        margin-top: 20px;
        justify-content: space-between;
        width: 40%;
    }

    .ac-template-page-clients {
        display: flex;
        justify-content: space-between;
        /* padding: 20px; */
        /* Adjust padding as necessary */
        /* gap: 20px; */
        margin-top: 50px;
        /* Space between content and hamburger menu */
    }

    .ac-client-details-container {
        margin-right: 0;
        /* Content can take full width on smaller screens */
        padding: 20px;
    }

    .ac-main-card {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .ac-main-card-prop {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .ac-client-photo-container {
        /* Add path to client photo and style as needed */

        margin-bottom: 50px;
    }



    .ac-card-content {
        flex-direction: column;
        align-items: center;
    }

    .ac-info-cards {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }



    .ac-action-cards {
        justify-content: center;
        /* Center the cards when they're stacked */
    }

    .ac-action-card {
        width: 100%;
        /* Full width cards on mobile */
        height: auto;
        /* Adjust height automatically to content */
    }

    .ac-card-icon {
        /* Adjust icon size for mobile if needed */
        width: 60px;
        /* Keep a fixed size for icons on mobile */
        height: 60px;
        /* Keep a fixed size for icons on mobile */
    }

    .ac-client-back-icon {
        margin-bottom: 20px;
        align-self: start;
    }

    .ac-date-picker {
        width: 40%;
    }

    .ac-info-card-title {
        font-size: 16px;
    }

    .ac-info-card-content {
        font-size: 14px;
    }

    .ac-main-card {
        width: 100%;
        /* overflow: auto; */
        flex-direction: column;
        flex-direction: column;
        align-items: center;
    }

    .ac-main-card-prop {
        width: 100%;
        flex-direction: column;
        flex-direction: column;
        align-items: center;
    }

    .ac-info-card {
        margin-top: 30px;
        /* width: 100%; */
    }

    .ac-info-card-container {
        flex-wrap: wrap;
    }

    .ac-financial-amount {
        font-size: 36px;
        /* Reduce font size for smaller screens */
    }

    .ac-financial-circle-amount {
        min-width: 90px;
        min-height: 90px;
        /* Adjust the circle size for mobile screens */
    }

    .ac-financial-cards,
    .ac-financial-info {
        flex-direction: column;
        /* Stack cards vertically on smaller screens */
        gap: 10px;
        /* Reduce gap for compact layout */
    }

    .ac-financial-card {
        flex-direction: column;
        /* Stack card content vertically for better readability */
        gap: 30px;
        /* Reduce gap for compact layout */
    }
}

@media (min-width: 1000px) {
    .ac-card-content {
        flex-wrap: wrap;
        margin-top: 20px;
    }

    /* .ac-main-card {
        min-width: 775px;
    }

    .ac-main-card-prop {
        min-width: 775px;

    } */

}