.dialog {
    border-radius: var(--Spacing-xl, 16px) !important;
    background: var(--Color-white, #FFF);
}

.dialogContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 30px ;
    overflow: hidden;

}

.frame2393 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.frame2392 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
}

.frame46 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.passwordChangedSuccessfully {
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.successfully {
    color: rgba(44, 216, 82, 1);
    font-weight: 600;
}

.usePasswordSuccessfully {
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.backToLoginButton {
    margin-top: 16px;
    background-color: #3b82f6;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
    height: var(--Spacing-big-size, 64px);
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #304FFF);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
}

.animationsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}