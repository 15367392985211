body,
h1,
h2 {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.Document {
  display: flex;
  justify-content: space-between;
}


.document__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* Allows items to wrap to the next line on smaller screens */
  margin-top: 20px;
  padding: 0 15px;
  /* Add padding for better spacing */
}

.PAitems_Container {
  text-align: right;
  direction: rtl;
  background-color: #faf9f9;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.income__container h1 {
  margin-left: 10px;
  color: #030303;
  font-size: 48px;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 48px;
}



.input-field-dropdown {
  margin-right: 1rem;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-sizing: border-box;
}





.dropdown {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}

.dropdown:focus {
  border-color: #007bff;
}

.pdf-td {
  position: relative;
  width: 300px;
  height: 200px;
  cursor: pointer;
}

.pdf-td-payment-acceptance {
  position: relative;
  width: 200px;
  height: 200px;
  cursor: pointer;
}




.my-select__control {
  background-color: white;
  /* border-color: blue; */
  /* other styles */
  width: 160px;
  color: #000;
}

.my-select__option--is-selected {
  background-color: red;
  /* other styles */
  color: #000;
}

.my-select__option--is-focused {
  background-color: lightgray;
  /* other styles */
  color: #000;

}

.table-pdf {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  /* z-index: 1; */
  /* Ensure the overlay is above this */
}

/* Styles for Text in Table Items */
.table-items__container text {
  color: #b42222;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 20px;
}

/* Modal Background */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it's above other elements */
}



/* Input Styles */
/* Input Styles */

.date-input {
  height: 40px;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* Full width */
  box-sizing: border-box;
}

/* Button Styles */
.document-custom-input1 {
  height: auto !important;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* Full width */
  box-sizing: border-box;
}



/* DatePicker Styles - Optional */
.react-datepicker-wrapper {
  width: 100%;
  /* Ensures the picker takes full width of parent */
}



.date-section2 {
  margin: 10px 0;
  text-align: center;
  /* height: 20px; */
}



.date-input-container:first-child button {
  cursor: pointer;
}



.date-input-container2 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 160px;
}

.date-input-container2:first-child button {
  cursor: pointer;
}

.date-input-container2:first-child button:hover {
  background-color: #020202;
  color: white;
}

.calendar-icon {

  /* Adjust the left position as needed */
  width: 20px;
  height: 20px;
  /* Adjust the size as needed */
  height: 20px;
  fill: #333;
  /* Icon color */
  cursor: pointer;
  margin-right: 10px;

}

.date-input {
  padding-left: 40px;
  /* Adjust the left padding to make space for the icon */
  /* Add any other styling you need for the input */
}

.button-row {
  display: flex;
  justify-content: space-between;
}

.button-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}






/* Hide the actual file input visually, but make it still be focusable by screen readers for accessibility */
.input-file {
  display: none;
}



.error-message-create-document {
  color: red;
  margin-bottom: 1rem;
  /* Add space below the error message */
}

.ok-message-create-document {
  color: green;
  margin-bottom: 1rem;
  /* Add space below the error message */
}

/* Make sure the DatePicker input is styled consistently */
.react-datepicker-wrapper {
  width: 100%;
  /* Ensures the picker takes full width of the container */
}

.react-datepicker__input-container {
  width: 100%;
  /* Ensures the picker input takes full width */
}

.create-modal-overlay {
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}





.select-field {
  color: 'black';
}

.providerId-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.row-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row-buttons-icon {
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;

    padding: 20px;

    font-size: 0.9em;
  }


  main {
    width: 100%;
  }

}

.horizontal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* Ensure items start from the beginning */
  width: max-content;
  /* Ensure the container fits all items */
}

.columns-of-scroll {
  padding: 10px;
  background-color: #61dafb;
  margin: 5px;
  border-radius: 5px;
  min-width: 150px;
  min-height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-middle-seperator {
  height: 80px;
  width: 2px;
  background-color: rgb(255, 255, 255);
  margin: 0 10px;
  border-width: 2px;
  border-radius: 4px;
}

.specific-type-div {
  display: flex;
  flex-direction: column;
}

.specific-type-div-children {
  display: flex;
  flex-direction: row;
}


.table-container table {
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.iframe-overlay-payment-acceptance {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100px;
  /* Set the desired width */
  height: 100px;
  /* Set the desired height */
  /* Increase the z-index to cover the iframe */
  background-color: transparent;
  /* Keep it invisible */
}

.inputEdit__container {
  text-align: right;
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
}

