body,
h1,
h2,
table {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.Document {
  display: flex;
  justify-content: space-between;
}

.search-bar__container-transactions {
  justify-content: flex-end;
}


.filter-button-document {
  /* width: auto;
  min-width: 140px; */
  height: 36px;
  padding: 0px 8px;
  border-radius: 2px;
  border: 1px solid #025366;
  background: #025366;
  color: var(--background, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  outline: none;
  cursor: pointer;
  width: 200px;
  height: 50px;
  margin-right: 10px;
}


.document__container {
  display: flex;
  align-items: center;
  /* This will push the children to opposite ends */
  flex-wrap: wrap;
  /* Allows items to wrap to the next line on smaller screens */
  margin-top: 20px;
  padding: 0 15px;
  /* Add padding for better spacing */
}

.income__container h1 {
  margin-left: 10px;
  color: #030303;
  font-size: 48px;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 48px;
}

.input-field-dropdown {
  margin-right: 1rem;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-sizing: border-box;
}



.filter-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  /* background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1); */
  width: 100%;
  height: 66px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.dropdown {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}

.dropdown:focus {
  border-color: #007bff;
}





/* Custom Table Styles */
.custom-table {
  width: 100%;
  border-collapse: separate;
  /* Changed to separate for row spacing */
  border-spacing: 0 10px;
  /* Vertical spacing between rows */
  margin-top: 20px;
  table-layout: auto;
  overflow-x: auto;
}

/* Table Header Styles */
.custom-table thead {
  width: 100%;
}

.custom-table thead th {
  padding: 25px;
  color: #E57C22;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  background: #025366;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  border: 1px solid black;
  min-width: 80px;
}

/* Table Row and Data Cell Styles */
/* .custom-table tbody tr {
  background-color: #ffffff;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
} */

.custom-table td {
  padding: 8px;
  color: black;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  background: #025366;
  text-align: center;

}

/* Image Cell Styles */


.pdf-td {
  position: relative;
  width: 300px;
  height: 200px;
  cursor: pointer;
}

.iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  /* Increase the z-index to cover the iframe */
  background-color: transparent;
  /* Keep it invisible */
}

.my-select__control {
  background-color: white;
  /* border-color: blue; */
  /* other styles */
  width: 160px;
  color: #000;
}

.my-select__option--is-selected {
  /* background-color: red; */
  /* other styles */
  color: #000;
}

.my-select__option--is-focused {
  background-color: lightgray;
  /* other styles */
  color: #000;

}

.table-pdf {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  /* z-index: 1; */
  /* Ensure the overlay is above this */
}

/* Styles for Text in Table Items */
.table-items__container text {
  color: #b42222;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 20px;
}

/* Modal Background */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it's above other elements */
}



.create-buttons:disabled {
  color: #ccc;
  /* Grey text color for disabled state */
  background-color: #6c757d;
  /* Grey background color for disabled state */
  border: 1px solid #6c757d;
  /* Optional: Grey border color for disabled state */
}

.document-custom-input {
  width: 200px;
  height: 50px;
  /* margin: 10px 0; */
  padding: 10px;
  font-size: 16px;
  color: 'black';
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #025366;
  background: var(--background, #FFF);
}

.download-document-custom-input {
  height: 40px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* Full width */
  box-sizing: border-box;
}

.document-custom-input-range {
  margin-bottom: 15px;
}

.document-download-icon {
  cursor: pointer;
  margin-top: 10px;
}

.document-download-modal-overlay {
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}




.download-report-title {
  color: #030303;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 26px;
  text-align: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.download-document-select {
  width: 200px;
}

.download-document-date-picker {
  width: 200px;
}


.download-Cancel-create-buttons {
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  /* flex-grow: 1; */
  width: 180.589px;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);

  background: white;
  height: 70.024px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.download-Cancel-create-buttons:hover {
  transform: scale(1.01);
  /* Darker shade for hover effect */
}


.cancel-download-create-buttons {
  background-color: #000;
  color: #000;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  /* flex-grow: 1; */
  width: 180.589px;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);
  height: 70.024px;
  background: #FFF;

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.cancel-download-create-buttons:hover {
  background-color: #444;
  /* Darker shade for hover effect */
}

.download-document-input-field,
.my-select__control,
.date-input {
  width: 100%;
  /* Ensures inputs take full width of their container */
}

.download-document-input-field {
  height: 40px;
  /* margin: 10px 0; */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: 'black';
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  /* Full width */
  box-sizing: border-box;
  text-align: center;

}

.document-download-modal-buttons-container {
  display: flex;
  justify-content: space-around;

}

.document-download-modal-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.document-download-modal-button:hover {
  opacity: 0.8;
}

.download-my-select__control {
  background-color: white;
  /* border-color: blue; */
  /* other styles */
  width: 200px;
  color: #000;
}

.download-my-select__option--is-selected {
  /* background-color: red; */
  /* other styles */
  color: #000;
}

.download-my-select__option--is-focused {
  background-color: lightgray;
  /* other styles */
  color: #000;

}



/* Button Styles */
.document-custom-input1 {
  height: auto !important;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* Full width */
  box-sizing: border-box;
}

.choose-buttons {
  color: white;
  padding: 10px 20px;
  width: 130px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #025366;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 5px;
  flex-grow: 1;
}

.choose-buttons:hover,
.choose-buttons.active {
  background-color: #E57C22;
  /* Darker shade for hover effect */
}

.choose-buttons+.choose-buttons {
  margin: 0 80px 0 0px;
  /* Adds margin to the left of every button that follows another button */
}

.create-buttons {
  color: white;
  padding: 10px 20px;
  width: 120.589px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #025366;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 5px;
  flex-grow: 1;
}

.create-buttons:hover {
  background-color: #E57C22;
  /* Darker shade for hover effect */
}

/* DatePicker Styles - Optional */
.react-datepicker-wrapper {
  width: 100%;
  /* Ensures the picker takes full width of parent */
}

.date-section {
  margin: 10px 0;
  text-align: center;
}

.date-section2 {
  margin: 10px 0;
  text-align: center;
  /* height: 20px; */
}

.date-input-container {
  display: flex;
  align-items: center;
  height: 20px;
}

.date-input-container:first-child button {
  cursor: pointer;
}

.date-input-container:first-child button:hover {
  background-color: #E57C22;
  color: #025366;
}

.date-input-container2 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 160px;
}

.date-input-container2:first-child button {
  cursor: pointer;
}

.date-input-container2:first-child button:hover {
  background-color: #020202;
  color: white;
}

.calendar-icon {

  /* Adjust the left position as needed */
  width: 20px;
  height: 20px;
  /* Adjust the size as needed */
  height: 20px;
  fill: #333;
  /* Icon color */
  cursor: pointer;
  margin-right: 10px;
}

.date-input {
  padding-left: 40px;
  /* Adjust the left padding to make space for the icon */
  /* Add any other styling you need for the input */
}

.button-row {
  display: flex;
  justify-content: space-between;
}


.file-upload-container {
  margin: 10px 0;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  box-sizing: border-box;
}

.upload-label {
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: right;
  width: 100%;
  box-sizing: border-box;
}

/* Hide the actual file input visually, but make it still be focusable by screen readers for accessibility */
.input-file {
  display: none;
}

/* Optional: If you want to show the selected file name */
.upload-label::after {
  content: attr(data-file-name);
  display: block;
  margin-left: 10px;
  color: #888;
  font-size: 0.8em;
}

.error-message-create-document {
  color: red;
  margin-bottom: 1rem;
  /* Add space below the error message */
}

.ok-message-create-document {
  color: green;
  margin-bottom: 1rem;
  /* Add space below the error message */
}

/* Make sure the DatePicker input is styled consistently */
.react-datepicker-wrapper {
  width: 100%;
  /* Ensures the picker takes full width of the container */
}

.react-datepicker__input-container {
  width: 100%;
  /* Ensures the picker input takes full width */
}

.create-modal-overlay {
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  animation: fadeInUp 0.2s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



.arrow {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.select-field {
  color: 'black';
}

.providerId-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row-buttons-icon {
  width: 30px;
  height: 30px;
}

.document-custom-input:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}

.filter-button-document:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}


.document-custom-input {
  cursor: pointer;
}

.document-date-range-toggle {
  border-radius: 2px;
  border: 1px solid #025366;

  background: #025366;
  /* Remove borders */
  /* White text */
  padding: 10px 24px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  color: var(--background, #FFF);

  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  /* Rounded corners */
  margin-right: 10px;
  /* Space from the right */
  transition: background-color 0.3s, transform 0.2s;
  /* Smooth transition for hover effect */
  width: 200px;
  height: 50px;
}

.document-date-range-toggle:hover {
  background-color: #E57C22;
  /* Darker shade of blue on hover */
  transform: scale(1.05);
  /* Slightly increase button size on hover */
}

.document-date-range-toggle:focus {
  outline: none;
  /* Remove focus outline */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  /* Custom focus style with blue glow */
}

.document-date-range-toggle:active {
  background-color: #005CBF;
  /* Even darker background for pressed effect */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  /* Inner shadow for depth */
}

.document-date-picker-label {
  display: block;
  /* Make the label display as a block */
  font-size: 14px;
  /* Font size */
  color: #4A4A4A;
  /* Color */
  font-weight: 500;
  /* Font weight */

}

.document-style-icon-download {
  height: 30px;
  /* or any size that fits your design */
  width: 30px;
  /* Maintain aspect ratio */
  /* Additional styles if needed */
  /* filter: invert(100%); */
}


.highlighted-row {
  border: 3px solid red;
  border-collapse: collapse;
  position: relative;
  /* Add this to position the star relative to the highlighted row */
}

.highlighted-row td {
  border-top: 3px solid red;
  border-bottom: 3px solid red;
}

.highlighted-row td:first-child {
  border-left: 3px solid red;
}

.highlighted-row td:last-child {
  border-right: 3px solid red;
}

/* Add this to create a red star */
.highlighted-row::after {
  content: '❗';
  /* Unicode character for a star */
  color: red;
  /* Star color */
  position: absolute;
  /* Absolute position relative to the .highlighted-row */
  top: 0;
  /* Position at the top */
  right: 0;
  /* Position at the right */
  font-size: 44px;
  /* Adjust the size of the star */
  /* transform: translate(50%, -50%); Adjust the star's position to make it half outside */
}

.documentDone-icon {
  width: 30px;
  height: 30px;
}



.document-search-bar {
  padding: 8px 8px 8px 40px;
  /* Adjust left padding to accommodate the icon */
  border: none;
  box-shadow: 2px 2px 4px rgba(3, 3, 3, 0.1);
  background-color: #ffffff;
  color: #a9a9a9;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  width: 382.548px;
  height: 35.813px;
  max-width: 500px;
  border-radius: 24px;
  background-image: url(../../assests/images/search.png);
  background-position: 10px center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  text-align: right;
  padding-right: 20px;
  /* Maintain original right padding for text alignment */
}

.document-date-picker {
  width: 200px
}



.totalPrice {
  background: var(--background, #E57C22);
}

.date-picker-wrapper {
  width: 100%;
}

.edit-modal-content-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-grow: 1;
  overflow-x: auto;
}



.edit-table-pdf {
  width: 100%;
  height: 95%;
  border-radius: 8px;
  /* position: relative; */
}



.edit-document-title-payment {
  flex-grow: 1;
  height: 70px;
  border-radius: 5px;
  color: var(--background, #000000);

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}



.edit-document-input-description {
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}


.add-document-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-self: center;
  margin-bottom: 20px;
  margin-top: 20px;
}




.edit-my-select__option--is-selected {
  /* background-color: red; */
  /* other styles */

  color: #000;
}

.edit-my-select__option--is-focused {
  background-color: lightgray;
  /* other styles */
  color: #000;

}


.items_Container {
  width: 100%;

}

.transaction_Container {
  width: 80%;
  text-align: right;
  direction: rtl;
  background-color: #faf9f9;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.clientItemButton {
  width: 81%;
  display: flex;
  background: var(--bg-input, #F5F5F6);
  border: none;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-radius: var(--Spacing-md, 8px);
  color: var(--Foundation-Blue-Darker, #111C59);
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.clientItemButton-small {
  width: 81%;
  display: flex;
  background: var(--bg-input, #F5F5F6);
  border: none;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-radius: var(--Spacing-md, 8px);
  color: var(--Foundation-Blue-Darker, #111C59);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}


.clientItemButton:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #e0e0e0; /* Adjust this color to suit your design */
}


.clientItemButton-small:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #e0e0e0; /* Adjust this color to suit your design */
}





.clientItemListBack {
  transform: rotate(180deg);
}

.clientItemCancelButton {
  display: flex;
  /* flex-direction: column; */
  background: none;
  /* Removes the background */
  border: none;
  /* Removes the border */
  padding: 0;
  /* Removes padding */
  margin: 0;
  /* Removes margin */
  cursor: pointer;
  /* Changes cursor to pointer to indicate clickability */
  outline: none;
  /* Removes the outline */
  align-items: center;
  /* Centers the icon vertically */
  /* justify-content: flex-end; */
  /* Centers the icon horizontally */
  font-size: 16px;
}

.clientItemCancelButton:hover {
  transform: scale(1.05);
}

.clientItem-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.item_Container {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-field_Container {
  width: 100%;
  display: flex;
  align-items: center;

}

.input-field_Container_required-asterisk {
  border-style: solid;
  border-color: red
}

.input-field-title {
  color: var(--foreground, #000);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  width: 150px;
}


.modal-Invoice-choice-content {
  background-color: white;
  border-radius: 20px;
  padding: 35px;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  max-height:700px;
  overflow-y: auto;
  /* Fixed width */
  display: flex;
  flex-direction: column;
  /* Stack the children vertically */
  /* margin: 5% auto; */
  /* Centers the modal with 5% top margin */
  max-width: 96%;
  /* Prevents the modal from exceeding the width of the screen */
  box-sizing: border-box;
  /* Includes padding in the width */
  /* margin: 0 auto; */
  margin-top: 5%;
  height: 600px;
  overflow-y: auto;
  gap: 25px;
}

.accountName_item_Container {
  display: flex;
  width: 100%;

}

.signatureCanvas {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  background-color: #fff;
}



.headerTH-Text {
  width: 140px;

}

@media (max-width: 809px) {



  .document-custom-input {
    font-size: 12px !important;
  }


  .filter-container {
    padding-bottom: 10px;
    padding-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    width: auto !important;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .filter-date-container {
    padding-bottom: 10px;
    padding-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: row;
    width: auto !important;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
    gap: 10px;
  }

  .document__container {
    width: 100%;
    justify-content: space-between;
  }

  .custom-table {
    margin: 0 auto;
    width: 100%;
  }


  .custom-table-container {
    overflow-x: auto;
    margin: 0 -15px;
  }


  .edit-modal-content-details {
    margin-top: 100px;
  }


  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  .document__container {
    justify-content: flex-end;
  }
}