/* Container for the entire scroll area */
.chat-messages-container {
    width: 100%;
    overflow: auto;
    padding: 15px;
    box-sizing: border-box !important;
    /* critical for virtualization */
    background-color: #ffffff;
}

/* Each message row (virtual item) */
.chat-message-row {
    position: relative;
}

/* Align the bubble row either to the left or right */
.bubble-row {
    display: flex;
    width: 80%;
    box-sizing: border-box !important;
    margin-bottom: 8px;
    /* Separation between messages */
}

.bubble-row-left {
    justify-content: flex-start;
}

.bubble-row-right {
    justify-content: flex-end;
}

/* Bubble container */
.bubble-container {
    max-width: 100%;
    /* Bubble width limit */
    position: relative;
    border-radius: 12px;
    padding: 10px 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    word-break: break-word;
    /* Handle long text properly */
    overflow: hidden;
    /* For image/file alignment */
}

/* Left bubble (receiver) */
.bubble-left {
    background-color: #F2F2F7;
    color: #2C2C2E;
    align-items: flex-start;
    text-align: left;
}

/* Right bubble (sender) */
.bubble-right {
    background-color: #007AFF;
    color: #ffffff;
    align-items: flex-end;
    text-align: right;
}

/* Bubble content (text, images, etc.) */
.bubble-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* Space between content */
}

/* Timestamp styling */
.bubble-timestamp {
    font-size: 11px;
    color: #FFF;
    margin-top: 5px;
    align-self: flex-end;
}

.bubble-timestamp-receiver {
    font-size: 11px;
    color: black;
    margin-top: 5px;
    align-self: flex-end;
}

/* Text bubble styling */
.text-bubble span {
    font-size: 14px;
    line-height: 1.5;
}

/* Photo bubble styling */
.photo-bubble {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.photo-image {
    width: 150px;
    /* Fixed width */
    height: 150px;
    /* Fixed height */
    border-radius: 8px;
    /* Optional: Rounded corners */
    object-fit: cover;
    /* Ensure the image fits without distortion */
    cursor: pointer;
    /* Show pointer on hover */
    border: 1px solid #ddd;
    /* Optional: Border for better visibility */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: Subtle shadow */
    transition: transform 0.2s;
    /* Smooth hover effect */
}

/* File bubble styling */
.file-bubble .file-item {
    margin: 4px 0;
    cursor: pointer;
}

.file-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin: 5px;
    padding: 10px; */
    border-radius: 5px;
    overflow: hidden;
    width: 150px;
    /* Fixed width */
    height: 150px;
    /* Fixed height */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.file-preview:hover {
    transform: scale(1.05);
    /* Slight zoom on hover */
    /* box-shadow: inset 0 0 0 5px #0056b3; */
    /* Change inner border thickness and color */
}

.image-preview {
    width: 200px;
    height: 120px;
    object-fit: cover;
}

.pdf-preview {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
    box-shadow: inset 0 0 0 5px #0056b3;
    cursor: pointer;
}

.video-preview,
.audio-preview {
    width: 180px;
    height: 100px;
}

.text-preview,
.unsupported-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.file-name {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    word-wrap: break-word;
}

.iframe-preview {
    width: 100%;
    height: 100%;
    border: none;
    pointer-events: none;
    /* Disable interactions with the iframe */
}

.chat-iframe-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    /* Transparent background */
    cursor: pointer;
    /* Show pointer cursor */
    z-index: 1;
    /* Ensure it appears above the iframe */
    transition: background 0.3s ease;
}

.chat-iframe-overlay:hover {
    background: rgba(0, 0, 0, 0.1);
    /* Add a slight overlay effect on hover */
}

/* Bubble Container */
.bubble-container {
    border-radius: 15px;
    padding: 10px;
    max-width: 100%;
    word-wrap: break-word;
    margin: 5px 0;
}

/* Sending Status */
.bubble-status {
    font-size: 0.85em;
    color: gray;
    margin-top: 5px;
}

.status-sending {
    color: #ffa500;
    /* Orange for sending */
}

.status-success {
    color: #4caf50;
    /* Green for success */
}

.status-failed {
    color: #f44336;
    /* Red for failed */
}

.read-at-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    padding: 6px 12px;
    background-color: #f0f0f0;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.read-at-text {
    font-size: 0.75em;
    color: #6c757d;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}


.sender-name {
    font-size: 12px;
    font-weight: bold;
    color: #4a90e2;
    text-align: left;
    /* Modern blue color */
    margin-bottom: 5px;
    padding-left: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to right, #4a90e2, #50a7ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sender-name2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.sender-name:hover {
    color: #50a7ff;
    text-decoration: underline;
    cursor: pointer;
}

.MuiMenu-paper {
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 5px;
}

.MuiMenuItem-root {
    font-size: 14px;
    padding: 10px 15px;
}

.chat-message-row.selected {
    background-color: #f0f8ff;
}

.bulk-actions {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    gap: 10px;
    background-color: white;
  }
  
  .bulk-delete-button,
  .exit-multi-select-button {
    background-color: #304fff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .bulk-delete-button {
    background-color: red;
  }