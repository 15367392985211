.table-upper-panel-bank-matching {
  height: auto;
  /* Adjust the height as needed */
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
  border-radius: 20px 20px 0 0;
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.table-titles {
  color: var(--foreground, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  background-color: #ebebeb;
}

.animated-upload-icon {
  margin-top: 10px;
  font-size: 48px;
  /* Make the icon larger */
  animation: bounce 1.5s infinite;
  color: #3f51b5;
  /* You can change the color as needed */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
    /* Bounce up */
  }

  60% {
    transform: translateY(-7px);
    /* Bounce down */
  }
}


.matched-color-0 .MuiDataGrid-row {
  background-color: #FFCDD2 !important;
  /* Light Red */
}

.matched-color-1 .MuiDataGrid-row {
  background-color: #C8E6C9 !important;
  /* Light Green */
}

.matched-color-2 .MuiDataGrid-row {
  background-color: #BBDEFB !important;
  /* Light Blue */
}

.matched-color-3 .MuiDataGrid-row {
  background-color: #FFF9C4 !important;
  /* Light Yellow */
}

.matched-color-4 .MuiDataGrid-row {
  background-color: #D1C4E9 !important;
  /* Light Purple */
}

.matched-color-5 .MuiDataGrid-row {
  background-color: #FFECB3 !important;
  /* Light Orange */
}

.matched-color-6 .MuiDataGrid-row {
  background-color: #B2DFDB !important;
  /* Light Teal */
}

.button-row-JournalEntryMatch {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;

}

.JournalEntryMatch-create-buttons {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  /* flex-grow: 1; */

  width: 100px;
  border-radius: 8px;
  border: 1px solid var(--foreground, #000);

  background: #304FFF;
  height: 30px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.JournalEntryMatch-create-buttons:hover {
  transform: scale(1.01);
  /* Darker shade for hover effect */
}

.JournalEntryMatch-cancel-buttons {
  background-color: #000;
  color: var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A));
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  /* flex-grow: 1; */
  width: 100px;
  border-radius: var(--Spacing-md, 8px);
  border: 2px solid var(--orange, #F9AA2A);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);

  background: white;
  height: 30px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.JournalEntryMatch-cancel-buttons:hover {
  transform: scale(1.01);
  /* Darker shade for hover effect */
}

.bank-form-row-item {
  display: flex;
  flex-direction: column;
  width:100%;
}