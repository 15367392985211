.checkbox-container {
    display: flex;                /* Use Flexbox */
    align-items: center;          /* Center vertically */
    justify-content: center;      /* Center horizontally */
    flex-direction: column;
    gap: 8px;                     /* Add some space between the checkbox and text */
}

.mui-checkboxes-labels{
    font-size: 10;
}

/* In your CSS file (e.g., styles.css or selectStyles.css) */
.custom-select__control {
    min-height: 30px; /* Set the smaller height */
    height: 40px;
    width: 170px; /* Set the larger width */
}

.custom-select__value-container {
    height: 30px; /* Set height to match control */
    padding: 0 6px; /* Custom padding */
}

.custom-select__input {
    margin: 0; /* Remove extra margin around input */
}

.custom-select__indicators {
    height: 30px; /* Match height with control */
}

.journalEntryInput{
    min-width: 150px !important;
}