.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modal {
    border-radius: var(--Spacing-xl, 16px);
    background: var(--white, #FFF);
    padding: 20px;
    text-align: center;
    width: 90%;
    max-width: 400px;
}

.modal-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.modal-title {
    color: var(--error, #FC2807);

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal-message {
    color: var(--black-90, rgba(0, 0, 0, 0.90));
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.modal-button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.cancel-button {
    border-radius: var(--Spacing-md, 8px);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    height: 44px;
    width: 30%;
    color: var(--error, #FFF);
    background-color: #304FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
}

.cancel-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15);
    background-color: #203FCC; /* Slightly darker shade */
}

.ok-button {
    border-radius: var(--Spacing-md, 8px);
    border: 2px solid var(--error, #FC2807);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    height: 44px;
    width: 30%;
    color: var(--error, #FC2807);
    background-color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
}

.ok-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15);
    background-color: #FCE8E3; /* Slightly lighter shade */
    color: #FC2807; /* Ensure text remains visible */
}

.ok-button-green {
    background-color: transparent;
    border: 2px solid #2CD852;
    color: #2CD852;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease, color 0.2s ease;
}

.ok-button-green:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15);
    background-color: #E6FCE7; /* Slightly lighter green shade for hover effect */
    color: #2CD852; /* Ensure text color stays the same */
}