@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.centeredView {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-out; /* Apply the animation */
}

.modalView {
    width: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.yearSelector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
}

.add-subtract-button {
    background-color: transparent;
    border-color: transparent;
    width: 50px; /* Set the desired width */
    height: 50px; /* Set the height to match the width */
    display: flex; /* Use Flexbox to center the content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow */
    border-radius: 50%; /* Make the button round */
    cursor: pointer;
}

.add-subtract-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box-shadow on hover */
}

.yearButton {
    font-size: 18px;
    margin: 0 10px;
}

.yearText {
    font-size: 18px;
}

.biMonthlyPickerItem {
    padding: 10px;
    margin: 5px 0;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}
