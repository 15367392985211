.sub-template-page {
    display: flex;
    min-height: 100vh;
    /* Full viewport height */
    padding: 100px 20px;
    /* Adjust padding as necessary */
    gap: 20px;
    background: #FCFCFF;
}

.sub-template-container {
    flex-grow: 1;
    /* Grow to use available space */
    margin-right: 320px;
    /* Adjust the right margin to prevent touching the hamburger menu */
    padding: 20px;
    overflow-y: auto;
    /* Allows for scrolling within the container */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.sub-search-and-language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* This will place the language selector on the left and search bar on the right */
    margin-bottom: 20px;
}

.sub-hamburger-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 250px;
    /* Width of your HamburgerMenu */
    height: 100vh;
    background: #ffffff;
    /* or any color you prefer */
    /* Other styles for the HamburgerMenu */
}

.sub-hamburger-menu-container {
    position: fixed;
    right: 0;
    top: 0;
    width: 280px;
    /* Fixed width for the hamburger menu */
    background-color: #ffffff;
    height: 100vh;
    /* Full height */
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
    /* Add other styles as necessary */
}

.sub-search-container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-grow: 1;
    padding: 0 20px;
    margin-top: 20px;
}

/* Styling for the search bar */
.sub-search-bar {
    padding: 8px 8px 8px 40px;
    /* Adjust left padding to accommodate the icon */
    border: none;
    box-shadow: 2px 2px 4px rgba(3, 3, 3, 0.1);
    background-color: #ffffff;
    color: #a9a9a9;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 24px;
    outline: none;
    width: 100%;
    max-width: 500px;
    border-radius: 24px;
    background-image: url(../../assests/images/search.png);
    background-position: 10px center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    text-align: right;
    padding-right: 20px;
    /* Maintain original right padding for text alignment */
}



.sub-rtl-text-align {
    direction: rtl;
    /* For RTL languages */
}

.sub-client-info-value {
    direction: ltr;
    /* Ensures numbers and mixed content are displayed left-to-right */
}

.sub-title-and-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
    margin-top: 50px;
}

.sub-folders-title {
    margin-left: 50px;
    color: #030303;
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 32px;
    flex-shrink: 0;
}

.sub-client-info-text {
    color: #030303;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 24px;
    text-align: start;
}

.sub-folder-info-details {
    display: flex;
    flex-direction: column;
}

.sub-filter-button {
    width: 235px;
    height: 37px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 9px;
    background: var(--background, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    color: black;
    font-size: 13px;
    font-family: "Roboto";
    font-weight: 600;
    line-height: 17px;
    outline: none;
    cursor: pointer;
}

.sub-folders-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 20px; */
    margin-bottom: 20px;

}

.sub-folder-card {
    min-width: 150px;
    min-height: 320px;
    flex-shrink: 0;
    padding: 20px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
    border-radius: 16px;
    background: #F4EDED;
    box-shadow: 0px 1px 4px 0px #E57C22;
    position: relative;

}

.sub-folder-thumbnail {
    width: 252px;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center;
}

.sub-folder-info {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.sub-view-button {
    padding: 5px 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.sub-view-button-rtl {
    order: -1;
    /* Place the button on the left */
    text-align: right;
    /* Align text to the right for RTL */
}

.centered-heading {
    text-align: center;
}

.sub-load-more {
    width: 100%;
    width: 253px;
    height: 48px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 25px;
    box-shadow: 0px 0px 10px rgba(3, 3, 3, 0.1);
    background-color: #030303;
    color: #ffffff;
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 20px;
    outline: none;
    margin-top: 20px;
    cursor: pointer;
}

.sub-style-icon-language {
    width: 20px;
    height: 20px;
}


.sub-load-more.sub-disabled {
    color: #ccc;
    /* Example color for disabled state */
    background-color: #f5f5f5;
    /* Light grey background for disabled state */
    cursor: default;
    /* Cursor style indicating the button is not clickable */
    /* Additional styling for disabled state */
}

.sub-popup-hamburger-overlay {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1050;
    /* Ensure this is high enough to be on top */
}

.sub-add-client__container {
    border-radius: var(--Spacing-xl, 16px);
    background: var(--white, #FFF);
    z-index: 1400 !important;
    /* Higher than overlay */
    /* Define a specific width and height as needed */
    width: 20%;
    min-width: 500px;
}

/* Add-client-content should be centered within add-client-background */
.sub-add-client-content {
    background-color: white;
    padding: 35px;
    border-radius: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 13051;
    /* Even higher z-index to be on top of the background */
    width: 100%;
    /* Width can be auto for content size or any specific width */
    /* max-width: 500px;  */
    /* Max width if needed */
    box-sizing: border-box;
}

.title-and-buttons {
    /* Flex container for title and buttons */
    display: flex;
    justify-content: space-between;
    /* Align items with space between */
    align-items: center;
    /* Center items vertically */
    margin-bottom: 20px;
}

.centered-heading {
    color: var(--black-90, rgba(0, 0, 0, 0.90));
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.edit-delete-buttons {
    /* Flex container for edit and delete buttons */
    display: flex;
}

.subEditDeleteButton {
    /* Style for button container */
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: var(--Spacing-md, 8px);
    background: rgba(252, 40, 7, 0.12);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
}

.subEditDeleteButton-icon {
    /* Style for button icon */
    font-size: 1.5rem;
    color: #000;
    /* Change color as needed */
}

.sub-add-subAcc-content-button {
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #304FFF);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    margin: 10px 5px;
    color: var(--background, #FFF);
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    width: 20%;
    height: 50px;
}

.sub-add-subAcc-content-button:hover {
    background-color: #F9AA2A;
    transition: background-color 0.3s ease;
}


.sub-add-subAcc-content-small-button {
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #304FFF);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    margin: 10px 5px;
    color: var(--background, #FFF);
    font-size: 15px;
    font-style: normal;
    font-weight: 100;
    width: 100%;
    height: 30px;
}

.sub-add-subAcc-content-small-button:hover {
    background-color: #F9AA2A;
    transition: background-color 0.3s ease;
}

.sub-close-subAcc-content-button {
    border-radius: var(--Spacing-md, 8px);
    border: 2px solid var(--orange, #F9AA2A);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    background: var(--Foundation-Blue-Normal, #FFF);
    cursor: pointer;
    margin: 10px 5px;
    color: var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A));
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    width: 20%;
    height: 50px;
}

.sub-close-subAcc-content-button:hover {
    background-color: #304FFF;
    transition: background-color 0.3s ease;
}

.sub-button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.sub-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sub-input-field {
    height: 40px;
    margin: 10px 0;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: 'black';
    background-color: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 80%;
    flex: 1;
    text-align: right;
    box-sizing: border-box;
}

.sub-title-input-field {
    margin: 10px 0;
    font-size: 16px;
    color: 'black';
    width: 40%;
}

.subAcc-full-width{
    width: 100% !important;
    margin-bottom: 20px;
}
@media (max-width: 768px) {
    .sub-template-page {
        /* Full viewport height */
        display: flex;
        justify-content: space-between;
        padding: 20px;
        /* Adjust padding as necessary */
        gap: 20px;
        margin-top: 30px;
        /* Space between content and hamburger menu */
    }

    .sub-template-container {
        margin-right: 0;
        /* Content can take full width on smaller screens */
        padding: 20px;
    }

    .sub-search-and-language,
    .sub-title-and-filter,
    .sub-folders-grid,
    .sub-load-more {
        padding: 0 10px;
        /* Adjust padding for smaller screens */
    }

    .sub-folders-grid {
        grid-template-columns: 1fr;
        /* Single column layout on smaller screens */
    }

    .sub-hamburger-menu-container {
        width: 100%;
        /* Full width for mobile view */
        height: auto;
        /* Adjust height for mobile */
        position: absolute;
    }

    .sub-folder-card {
        margin: 0 auto;
        margin-top: 25px;
    }
}