.mlu-modal-wrapper {
    position: absolute;
    top: 5%;
    left: 30%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    /* Limit modal height to 90% of the viewport */
    height: 90%;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1001;
    animation: fadeInScale 0.4s ease-in-out;
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.8);
        /* Start slightly smaller */
    }

    to {
        opacity: 1;
        transform: scale(1);
        /* Full size */
    }
}

.mlu-modal-header {
    cursor: move;
    /* This part is the draggable handle */
    background-color: #304FFF;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mlu-title {
    font-size: 18px;
    font-weight: bold;
}

.mlu-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
}

.mlu-modal-body {
    padding: 16px;
    height: 100%;
    max-height: 80vh;
    overflow-y: auto;
}

.mlu-filter-row {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 12px;
    width: 100%;
}

.mlu-search-box {
    flex: 1;
    padding: 8px;
}

.mlu-viewmode-bar {
    display: flex;
    justify-content: space-around;

}

.mlu-viewmode-bar button {
    all: unset;
    box-sizing: border-box !important;
    margin-right: 0;
    padding: 0;
    cursor: pointer;
    /* Changed to indicate interactivity */
    display: flex;
    width: 200px;
    height: 64px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #EAEDFF);
    color: #304FFF;
    transition: background-color 0.3s ease, transform 0.2s ease;
    /* Smooth transition for hover */
}

/* Active state */
.mlu-viewmode-bar button.active {
    background-color: #304FFF;
    color: white;
}

/* Hover effect */
.mlu-viewmode-bar button:hover {
    background-color: #1e40ff;
    color: white;
    /* Slightly darker blue on hover */
    transform: scale(1.05);
    /* Subtle zoom effect */
}

/* Optional: Focus outline for accessibility */
.mlu-viewmode-bar button:focus {
    outline: 2px solid lightblue;
    outline-offset: 2px;
}

.mlu-select-row {
    margin: 12px 0;
    display: flex;
    align-items: center;
    gap: 16px;
}

.mlu-confirm-btn {
    all: unset;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-sm, 4px);
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Yellow-Normal, #F9AA2A);
}

.mlu-confirm-btn:hover {
    background: #d18d1f;
    /* Slightly darker yellow for hover */
    transform: scale(1.05);
    /* Slight zoom-in effect */
}

.mlu-confirm-btn:active {
    transform: scale(0.95);
    /* Slight shrink effect for click */
}

.mlu-confirm-btn.mlu-remove {
    color: #fff;
    /* White icon for Remove */
}

.mlu-confirm-btn.mlu-add {
    color: #fff;
    /* White icon for Add */
}

.mlu-users-list {
    background: #f9f9f9;
    border: 1px solid #eee;
    padding: 16px;
    margin-bottom: 12px;
}

.mlu-user-item {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 10px;
    gap: 8px;
    border-radius: 8px;
    background-color: #f9f9f9;
    /* Light background for better visibility */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.mlu-user-item:hover {
    background-color: #f1f1f1;
    /* Slightly darker background on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow effect */
}

.mlu-container {
    display: flex;
    flex-direction: column;
}

.mlu-username {
    font-weight: 500;
    /* Semi-bold font */
    color: #333;
    /* Darker color for better readability */
    font-size: 16px;
    /* Adjust size for consistency */
}

.mlu-caseType {
    font-size: 14px;
    font-weight: 500;
    color: #888;
    margin-top: 4px;
    text-align: right;
}

.mlu-user-item:active {
    background-color: #eaeaea;
    /* Even darker background on active */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* Slightly reduced shadow */
}

.mlu-user-item .mlu-unread {
    margin-left: auto;
    color: #666;
}

.mlu-empty {
    text-align: center;
    color: #999;
}

.mlu-scheduled-section {
    /* background: #f9f9f9; */
    padding: 16px;
    /* border: 1px solid #eee; */
}

.mlu-chat-bar {
    margin-top: 16px;
    border-top: 1px solid #ccc;
    padding-top: 16px;
}


.scheduled-item-container {
    border: 1px solid #ddd;
    border-radius: 6px;
    margin: 8px 0;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    background: #fff;
}

.scheduled-item-content {
    display: flex;
    flex-direction: column;
}

.file-carousel-container {
    margin-bottom: 8px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.subtitle {
    font-size: 14px;
    /* margin: 4px 0 0 0; */
}

.text-Container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}


.scheduled-item-actions {
    display: flex;
    gap: 8px;
    align-items: center;
}

.edit-btn,
.delete-btn {
    padding: 4px 12px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.edit-btn {
    background: #6A11CB;
    color: white;
}

.delete-btn {
    background: #FF512F;
    color: white;
}

/*******************************************************
   Scheduled Item Styles
********************************************************/
.scheduled-item-container {
    border-radius: 10px;
    margin: 12px 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    /* “fancy-bg” is added for gradient/“sexy” style */
    background: var(--white);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    /* Slight 3D effect on hover */
}

.scheduled-item-container.fancy-bg {
    background: linear-gradient(to right, #fdfbfb, #ebedee);
}

.scheduled-item-container:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.scheduled-item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Horizontal scrolling container for file previews */
.file-carousel-container {
    display: flex;
    flex-wrap: nowrap;
    /* overflow-x: auto; */
    gap: 12px;
    margin-bottom: 12px;
    padding-bottom: 6px;
}

/* Hide horizontal scrollbar (optional) */
.file-carousel-container::-webkit-scrollbar {
    height: 6px;
}

.file-carousel-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

.file-carousel-container:hover::-webkit-scrollbar-thumb {
    background-color: #999;
}

/*******************************************************
     File Preview Cards
  ********************************************************/
.file-preview-card {
    flex: 0 0 auto;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    width: 120px;
    height: 120px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Hover effect for the file preview card */
.file-preview-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* For images */
.image-card .file-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    cursor: pointer;
}

/* Audio card styling */
.audio-card audio {
    max-width: 110px;
}

/* PDF or generic fallback */
.pdf-card .file-button,
.generic-card .file-button {
    background: #304FFF;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.pdf-card .file-button:hover,
.generic-card .file-button:hover {
    background-color: #1e40ff;
}

/*******************************************************
     Text Info within the scheduled item
  ********************************************************/
.text-info {
    margin-top: 4px;
    color: var(--dark-text);
}

.title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
}

.subtitle {
    font-size: 14px;
    margin: 0;
    color: #F9AA2A;
    font-weight: bold;

}

.sender {
    font-size: 13px;
    color: #F9AA2A;
    font-weight: bold;
}

.sender-value {
    font-size: 13px;
    color: black;
    /* margin-top: 6px; */

}

/*******************************************************
     Action Buttons (Edit / Delete)
  ********************************************************/
.scheduled-item-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
    justify-content: center;
    margin-left: 16px;
}

/* Icon button styles */
.icon-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    /* Icon size */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 6px;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

/* Hover state to give a slight background highlight */
.icon-btn:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1.1);
}

/* Different colors for icons */
.icon-btn.edit {
    color: #6A11CB;
    /* Purple tone */
}

.icon-btn.delete {
    color: #FF512F;
    /* Reddish tone */
}

/*******************************************************
     Example “fancy” gradient button, if you need it
     (not required, just a reference)
  ********************************************************/
.fancy-btn {
    background: var(--primary-gradient);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
}

.fancy-btn:hover {
    filter: brightness(1.1);
    transform: translateY(-2px);
}

.addScheduled {
    all: unset;
    cursor: pointer;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-sm, 4px);
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Yellow-Normal, #F9AA2A);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease, transform 0.2s ease;

}

.addScheduled:hover {
    transform: scale(1.1);
}