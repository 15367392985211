.header-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 77px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: var(--background, #fff);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    flex-direction: row-reverse;
    padding: 12px 0 12px 0;
}

.user-info {
    flex-grow: 1;
    /* Allows the user info to grow and take available space */
    display: flex;
    justify-content: center;
    /* Centers the content of user-info */
    flex-direction: column;
    /* Aligns children vertically */
    text-align: center;
    /* Ensures text is centered within the div */
    margin: 0 auto;
}

.header-logo {
    width: 70px;
    height: 84.371px;
    flex-grow: 0;
    /* Prevents the logo from growing */
    cursor: pointer;
}

.user-name {
    color: var(--foreground, #000);
    text-align: center;
    font-size: 20px;
    font-style: normal;
}

.user-description{
    color: var(--foreground, #000);
    text-align: center;
    font-size: 16px;
    font-style: normal;
}
/* Responsive adjustments */
@media (max-width: 991px) {
    .header-div {
        /* flex-wrap: wrap; */
        gap: 40px;
        justify-content: center;
        /* Adjust to center the content on smaller screens */
        align-items: center;
    }

    .user-info {
        flex-basis: 100%;
        /* Make elements take full width on smaller screens */
    }

    .header-logo {
        order: -1;
        /* Ensures logo stays at the top */
        margin-bottom: 10px;
    }
}