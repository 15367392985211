.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: content-box !important;
  font-family: 'Alef', sans-serif !important;
}

.muirtl-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  box-sizing: border-box !important;
}

/* button,
input,
.button-class {
  box-sizing: border-box !important;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ltr-input .MuiInputBase-input {
  text-align: left;
}

.rtl-input .MuiInputBase-input {
  text-align: right;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.backIconBackground {
  display: flex;
  width: var(--Spacing-big-size, 30px);
  height: var(--Spacing-big-size, 30px);
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(--bg-input, #F5F5F6);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Apply transition to the default state */
}



.backIconBackground-edit {
  display: flex;
  width: var(--Spacing-big-size, 24px);
  height: var(--Spacing-big-size, 24px);
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(--bg-input, #F5F5F6);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Apply transition to the default state */
}

.backIconBackground:hover , .backIconBackground-edit:hover {
  background-color: var(--bg-hover, #E0E0E0);
  /* Change background on hover */
  transform: scale(1.05);
  /* Slight scaling to indicate clickable element */
}


.processed {
  border-radius: var(--Spacing-big-size, 64px);
  background: rgba(44, 216, 82, 0.20);
  display: flex;
  height: 28px;
  padding: 8px;

  justify-content: center;
  align-items: center;
  color: var(--Success, #2CD852);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.unprocessed {
  display: flex;
  height: 28px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: var(--Spacing-big-size, 64px);
  background: rgba(252, 40, 7, 0.20);

  color: var(--error, #FC2807);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.full-width{
  width: 100% !important;
}

.swal-high-zindex {
  z-index: 99999 !important; 
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
/* AddClient.css */
/* Ensure all inputs inside TextField and Select components use border-box */

