.selectable-avatar-container {
    /* Sizing for the avatar container */
    width: 40px;
    height: 40px;
    border-radius: 20px;

    /* Center content inside */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Optionally add transitions or a border */
    transition: border 0.2s;
}

/* When localSelected === true, we add a border to highlight */
.selectable-avatar-container.selected {
    border: 2px solid #304FFF;
    /* or another color */
}

/* The text inside the avatar when not selected */
.initials {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    user-select: none;
    /* prevent text highlight on click */
}

/* The checkmark symbol */
.checkmark {
    color: #fff;
    font-size: 20px;
    user-select: none;
}