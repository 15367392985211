/* ScheduledFormModal.css */
.scheduled-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.scheduled-modal-content {
    background: #fff;
    width: 600px;
    max-width: 90%;
    max-height: 95vh;
    height: auto;
    border-radius: 8px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box !important;
}

.scheduled-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #ddd;
}

.scheduled-modal-close-Item {
    all: unset;
    cursor: pointer;
    border-radius: 32px;
    background: var(--bg-input, #F5F5F6);
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: background-color 0.2s ease, transform 0.2s ease;

}

.scheduled-modal-close-Item:hover {
    transform: scale(1.1);
}

.sfm-content-wrapper {
    padding: 1rem;
}

/* Minimal or full scheduling forms */
.sfm-field {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

/* Attachments preview */
.sfm-attachments-list {
    margin-bottom: 1rem;
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 6px;
}

.sfm-attachment-item {
    padding: 0.5rem 0;
    border-bottom: 1px solid #f1f1f1;
    color: #333;
}

.sfm-attachment-item:last-child {
    border-bottom: none;
}

/* Buttons Row */
.sfm-button-row {
    display: flex;
    justify-content: space-around;
}

.sfm-cancel-btn {
    background: #ccc;
    border: none;
    border-radius: 6px;
    padding: 0.5rem 1.25rem;
    cursor: pointer;
}

.sfm-send-btn,
.sfm-save-btn {
    background: #304fff;
    border: none;
    color: #fff;
    border-radius: 6px;
    padding: 0.5rem 1.25rem;
    cursor: pointer;
}

.sfm-attachment-row {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.sfm-attachment-row button {
    cursor: pointer;
    background: #f3f3f3;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 6px;
    font-size: 0.875rem;
    color: #304fff;
}

.sfm-attachment-row button:hover {
    background: #eaeaea;
}

.attachment-list {
    margin-top: 16px;
    padding: 16px;

}

.attachment-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.attachment-preview {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    overflow: hidden;
    background: #f5f5f5;
}

.attachment-container {
    width: 100px;
    height: 100px;
    position: relative;
}

.attachment-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.attachment-pdf {
    width: 100%;
    height: 100%;
    border: none;
}

.attachment-file {
    font-size: 14px;
    text-align: center;
    word-break: break-word;
}

.message-content {
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.message-container {
    padding: 16px;
}

.message-content-preview {
    font-size: 14px;
    color: #333;
    word-wrap: break-word;
}

/* .sfm-addmode-container{
    overflow: auto;
} */

.sfm-save-btn:hover:not(.disabled) {
    background-color: #1E3BBB;
}

.sfm-save-btn.disabled {
    background-color: #D6D6D6;
    /* Gray background for disabled state */
    color: #A1A1A1;
    /* Lighter text color */
    cursor: not-allowed;
    /* Disabled cursor */
    pointer-events: none;
    /* Prevent hover effects */
}

.remove-attachment-btn {
    position: absolute;
    top: 0;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #FF5C5C;
    /* Red background for remove action */
    border: none;
    border-radius: 50%;
    /* Circle button */
    color: #FFFFFF;
    /* White icon or text */
    font-size: 16px;
    /* Icon or text size */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Subtle shadow */
}

.remove-attachment-btn:hover {
    background-color: #FF3B3B;
    /* Darker red on hover */
    transform: scale(1.1);
    /* Slightly larger on hover */
}

.remove-attachment-btn:active {
    background-color: #E60000;
    /* Even darker red on click */
    transform: scale(0.9);
    /* Slightly smaller on click */
}

.remove-attachment-btn:focus {
    outline: none;
    /* Remove default focus outline */
    box-shadow: 0 0 4px 2px rgba(255, 92, 92, 0.6);
    /* Custom focus shadow */
}