.UpperPanelDetailsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Additional styles if needed */
}

.UpperPanelDetailsGreetingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}



.UpperPanelDetailsGreetingContainerNoJustify {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.UpperPanelDetailsGreetingContainerNoJustify >div:first-child {
    color: var(--black-90, rgba(0, 0, 0, 0.90));

    /* text-24semi */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.UpperPanelDetailsGreetingContainerNoJustify >div:nth-child(2) {
    color: var(--Dark-56, rgba(0, 0, 0, 0.56));


    /* text-16regular */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.UpperPanelDetailsGreetingContainer>div:first-child {
    color: var(--black-90, rgba(0, 0, 0, 0.90));

    /* text-24semi */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.UpperPanelDetailsGreetingContainer>div:nth-child(2) {
    color: var(--Dark-56, rgba(0, 0, 0, 0.56));


    /* text-16regular */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.UpperPanelDetailsOperationsContainer {
    display: flex;
    align-items: center;
    /* Additional styles */
}

.notificationIcon {
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Light, #EAEDFF);
    padding: 8px;
    margin-right: 16px;
    cursor: pointer;
}

.profileContainer {
    display: flex;
    align-items: center;
    margin-right: 16px;
 
}

.profileContainerMargin {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-left: 16px;
 
}


.userName {
    margin : 0 8px;
    color: var(--black-90, rgba(0, 0, 0, 0.90));
    
    /* text-16semi */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* Additional styles for the user name */
}