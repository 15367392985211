body,
h1,
h2 {
  margin: 0;
  padding: 0;
  /* font-family: Arial, sans-serif; */
}

.table-headers {
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  
}


.table-headers:hover {
  background-color: #e1e1e1; /* Change this to your desired hover color */
}
.start-cell {
  display: flex;
  flex-direction: row-reverse;
  
}


.MuiChartsAxis-tickLabel {
  direction: rtl !important
}


.toggle-parent-container {
  display: flex;
  justify-content: space-between;
  /* Or space-around */
  align-items: center;
}

.rowHover {
  transition: transform 0.2s ease, box-shadow 0.1s ease;
  cursor: pointer;
}