.advancedDropdown-dropdown-panel {
    z-index: 10000 !important;
}

.custom-dropdown .p-dropdown {
    direction: rtl;
}

.custom-dropdown .p-dropdown-label {
    direction: ltr;
    text-align: left;
}

.custom-dropdown .p-dropdown-trigger {
    left: 0;
    right: auto;
}
.custom-dropdown .p-dropdown-label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.custom-dropdown .p-dropdown-label {
    text-align: center !important;
    width: 100%;
}

.advancedDropdown-dropdown-panel .p-dropdown-item{
    margin-bottom: 30px;
}