.draggable-modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px #aaa;
    overflow: hidden;
    z-index: 2000;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move; /* Indicates draggable area */
    padding: 10px;
    background-color: #f1f1f1;
}

.documentPreview-modal-content {
    flex-grow: 1;
    overflow: auto;
}