.hidden {
    visibility: hidden;
}

.pointer-events-disable {
    cursor: default !important;
}

.doc-delete-icon {
    width: 45px !important;
    height: 45px !important;
    margin-left: 10px;
}

.doc-delete-icon:hover {
    transform: scale(1.05);
}

.edit-buttons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    margin: 20px 0 20px 0;
    padding: 0 20px;
}

.edit-button-account-save {
    display: flex;
    height: var(--Spacing-big-size, 64px);
    padding: var(--Spacing-section, 32px) 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: var(--Spacing-md, 8px);
    background: var(--Foundation-Blue-Normal, #304FFF);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);

}

.edit-button-account-save:hover {
    background-color: #E57C22;
    /* Darker shade of blue on hover */
    transform: scale(1.01);
    /* Slightly increase button size on hover */
}

.edit-button-account-cancel {
    width: 100px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--foreground, #000);
    background: var(--background, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    color: var(--foreground, #000);

    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-left: 30px;
    cursor: pointer;
}

.edit-button-account-cancel:hover {
    background-color: #FFF;
    /* Darker shade of blue on hover */
    transform: scale(1.05);
    /* Slightly increase button size on hover */
}

.edit-modal-content-lower-panel {
    width: 100%;
    /* margin-top: 10px; */
}

.float-label-right label {
    float: right;
    margin-right: 10px;
}

.float-label-right .p-float-label-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.float-label-right label {
    right: 10px;
    left: unset;
}


.edit-button-account-save:disabled {
    color: #ccc;
    /* Grey text color for disabled state */
    background-color: #6c757d;
    /* Grey background color for disabled state */
    border: 1px solid #6c757d;
    /* Optional: Grey border color for disabled state */
}

.handle{
    cursor: move;
}