.frame33952,
.frame33952 * {
  box-sizing: border-box;
}

.frame33952 {
  border-radius: var(--spacing-md, 8px);
  border-style: solid;
  border-color: var(--drak-80, rgba(0, 0, 0, 0.8));
  border-width: 1px;
  padding: var(--spacing-lg, 12px) var(--spacing-md, 8px) var(--spacing-lg, 12px) var(--spacing-md, 8px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 140px;
  position: relative;
}

.english {
  color: var(--drak-80, rgba(0, 0, 0, 0.8));
  text-align: left;
  font-family: var(--text-16meduim-font-family,
      "Montserrat-Medium",
      sans-serif);
  font-size: var(--text-16meduim-font-size, 16px);
  font-weight: var(--text-16meduim-font-weight, 500);
  position: relative;
}

.keyboardArrowDown {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}

.languageSelector {
  position: relative;
  display: inline-block;
  z-index: 1000;
}

.frame33952 {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.keyboardArrowDown {
  margin-left: 8px;
}

.menu {
  position: absolute;
  top: 100%;
  width: 150px;
  border-radius: var(--Spacing-md, 8px);
  background: var(--white, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.32);
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: top;
  animation: openMenu 0.3s forwards;
}

@keyframes openMenu {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

.menuItem {
  padding: 10px;
  color: var(--Drak-80, rgba(0, 0, 0, 0.80));
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.menuItem:hover {
  background: var(--orange, #F9AA2A);
  color: #FFF;
}

.selectedItem {
  border-radius: var(--Spacing-sm, 4px);
  background: var(--orange, #F9AA2A);
  color: #FFF;
}