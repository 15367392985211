.assignments-page {
    padding: 20px;
  }
  
  .assignment-table {
    flex: 1;
    max-width: 30%;
    min-width: 300px;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .small-avatar {
    background-color: #ff5722; /* Equivalent to deepOrange[500] */
    width: 24px;
    height: 24px;
    font-size: 0.75rem;
}

.ql-editor {
  direction: rtl !important;
  text-align: right !important;
}

/* Target the editor's content to make all text 28px. */
.myCustomQuill .ql-editor {
  font-size: 20px;
}
